<script lang="ts" setup></script>

<template>
  <svg fill="none" height="32" viewBox="0 0 31 32" width="31" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6533 11.6578V13.9818H9.34805V11.6578C9.34805 10.579 8.04372 10.0387 7.28083 10.8016L2.93867 15.1437C2.46575 15.6166 2.46575 16.3833 2.93867 16.8562L7.28083 21.1984C8.04367 21.9612 9.34805 21.4209 9.34805 20.3421V18.0182H21.6533V20.3421C21.6533 21.421 22.9576 21.9613 23.7205 21.1984L28.0626 16.8563C28.5355 16.3834 28.5355 15.6167 28.0626 15.1438L23.7205 10.8016C22.9576 10.0387 21.6533 10.579 21.6533 11.6578Z"
      fill="#919191"
    />
  </svg>
</template>

<style lang="scss"></style>
