<script lang="ts" setup>
  import { toRef } from 'vue';

  import ContentHotel from '@/quotes/components/modals/ContentHotel.vue';
  import ContentExtension from '@/quotes/components/modals/ContentExtension.vue';
  import ContentTransfers from '@/quotes/components/modals/ContentTransfers.vue';
  import ContentLunch from '@/quotes/components/modals/ContentLunch.vue';
  import ContentTours from '@/quotes/components/modals/ContentTours.vue';
  import type { Hotel } from '@/quotes/interfaces';
  import type { ServiceExtensionsResponse } from '@/quotes/interfaces/services';
  import ModalComponent from '@/quotes/components/global/ModalComponent.vue';
  import IconClose from '@/quotes/components/icons/IconClose.vue';
  import type { Service } from '@/quotes/interfaces/services';
  import ContentMiscellaneous from '@/quotes/components/modals/ContentMiscellaneous.vue';

  interface Props {
    show: boolean;
    type: string;
    category: string;
    categoryName: string;
    title: string;
    hotel: Hotel;
    extension: ServiceExtensionsResponse;
    service?: Service;
    serviceDateOut?: Date | string;
  }

  const props = withDefaults(defineProps<Props>(), {
    show: false,
    type: 'transfers',
    category: undefined,
    title: '',
    categoryName: '',
    hotel: undefined,
    extension: undefined,
    service: undefined,
  });

  const hotel = toRef(props, 'hotel');
  const extension = toRef(props, 'extension');
  const service = toRef(props, 'service');
  const show = toRef(props, 'show');

  // Emits
  interface Emits {
    (e: 'close'): void;
  }

  const emits = defineEmits<Emits>();

  const onClose = () => {
    emits('close');
  };
</script>

<template>
  <ModalComponent v-if="show" :modalActive="show" class="modal-itinerario-detail" @close="onClose">
    <template #body>
      <div class="icon-close-modal" @click="onClose">
        <icon-close />
        <!-- {{ props.type }} - {{ props.category }} - {{ props.categoryName }} -->
      </div>
      <ContentHotel v-if="props.type == 'group_header' && hotel" :hotel="hotel" />
      <ContentExtension v-else-if="props.type == 'extension' && extension" :extension="extension" />

      <template v-if="props.type == 'service'">
        <!-- v-else-if="['1','10','9'].includes(props.type)" -->
        <ContentTransfers
          v-if="props.category == '1'"
          :service="service!"
          :service-date="serviceDateOut!"
          :categoryName="categoryName"
        />
        <ContentLunch
          v-else-if="props.category == '10'"
          :service="service!"
          :service-date="serviceDateOut!"
          :categoryName="categoryName"
        />
        <ContentTours
          v-else-if="props.category == '9'"
          :service="service!"
          :service-date="serviceDateOut!"
          :categoryName="categoryName"
        />
        <ContentMiscellaneous
          v-else-if="props.category == '14'"
          :title="props.title"
          :service="service!"
          :service-date="serviceDateOut!"
          :categoryName="categoryName"
        />
        <ContentTours
          v-else
          :title="props.title"
          :service="service!"
          :service-date="serviceDateOut!"
          :categoryName="categoryName"
        />
      </template>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped></style>
