import { storeToRefs } from 'pinia';
import type { Hotel, QuoteHotelsSearchRequest, SearchParameters } from '@/quotes/interfaces';
import { useQuoteHotelsStore } from '@/quotes/store/quote-hotels.store';
import { getHotelsAvailability } from '@/quotes/helpers/get-hotels-availability';
import useLoader from '@/quotes/composables/useLoader';

interface AvailabilityAndPromotions {
  type: AvailabilityAndPromotionsEnum;
  search_parameters: SearchParameters;
  hotels: Hotel[];
}

enum AvailabilityAndPromotionsEnum {
  availability = 'availability',
  promotions = 'promotions',
}

export const useQuoteHotels = () => {
  const store = useQuoteHotelsStore();
  const { showIsLoading, closeIsLoading } = useLoader();

  const { hotels, promotions, hotelSelected, searchParameters, hotelToEdit } = storeToRefs(store);

  return {
    // Props
    hotels,
    promotions,
    hotelSelected,
    hotelToEdit,
    searchParameters,

    // Methods
    getHotels: async (form: QuoteHotelsSearchRequest) => {
      showIsLoading();
      try {
        store.unsetHotels();

        const promises: Promise<AvailabilityAndPromotions>[] = [];

        promises.push(
          new Promise<AvailabilityAndPromotions>((resolve) => {
            getHotelsAvailability(form).then(({ data: [{ city }] }) => {
              resolve({
                type: AvailabilityAndPromotionsEnum.availability,
                search_parameters: city.search_parameters,
                hotels: city.hotels,
              });
            });
          })
        );

        promises.push(
          new Promise<AvailabilityAndPromotions>((resolve) => {
            getHotelsAvailability({
              ...form,
              type_classes: [1],
              typeclass_id: 'all',
              promotional_rate: 1,
            }).then(({ data: [{ city }] }) => {
              resolve({
                type: AvailabilityAndPromotionsEnum.promotions,
                search_parameters: city.search_parameters,
                hotels: city.hotels,
              });
            });
          })
        );

        const responses = await Promise.all(promises);
        responses.forEach(({ type, hotels, search_parameters }) => {
          if (type === AvailabilityAndPromotionsEnum.availability) {
            store.setHotels(hotels);
            store.setSearchParameters(search_parameters);
          }
          if (type === AvailabilityAndPromotionsEnum.promotions) {
            store.setPromotions(hotels);
          }
        });

        closeIsLoading();
      } catch (e) {
        console.log(e);
        closeIsLoading();
      }
    },
    getHotelsNoPromotions: async (form: QuoteHotelsSearchRequest) => {
      showIsLoading();
      try {
        store.unsetHotels();

        const promises: Promise<AvailabilityAndPromotions>[] = [];

        promises.push(
          new Promise<AvailabilityAndPromotions>((resolve) => {
            getHotelsAvailability(form).then(({ data: [{ city }] }) => {
              resolve({
                type: AvailabilityAndPromotionsEnum.availability,
                search_parameters: city.search_parameters,
                hotels: city.hotels,
              });
            });
          })
        );

        const responses = await Promise.all(promises);
        responses.forEach(({ type, hotels, search_parameters }) => {
          if (type === AvailabilityAndPromotionsEnum.availability) {
            store.setHotels(hotels);
            store.setSearchParameters(search_parameters);
          }
        });

        closeIsLoading();
      } catch (e) {
        console.log(e);
        closeIsLoading();
      }
    },
    setHotelSelected: async (hotelIdSelected: number) => {
      showIsLoading();
      store.setHotelSelected(hotelIdSelected);
      closeIsLoading();
    },
    unsetHotelSelected: () => store.unsetHotelSelected(),
    unsetHotelSelectedPromotions: () => store.unsetHotelSelectedPromotions(),
  };
};
