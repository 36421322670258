<template>
  <a-layout class="backend-dashboard">
    <a-layout-header>
      <b-header-component></b-header-component>
    </a-layout-header>
    <a-layout class="layout-content">
      <a-layout-sider
        class="sidebar-fixed"
        v-model:collapsed="collapsed"
        style="overflow: auto"
        collapsible
        @update:collapsed="handleCollapseChange"
      >
        <b-sidebar-menu :collapsed="collapsed"></b-sidebar-menu>
      </a-layout-sider>
      <a-layout-content
        :class="{ 'collapsed-content': collapsed, 'expanded-content': !collapsed }"
        style="padding: 20px"
      >
        <div class="dashboard-container">
          <b-breadcrumb class="breadcrumb-routes"></b-breadcrumb>
          <div class="content-wrapper">
            <router-view></router-view>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout-footer
      :class="{ 'collapsed-content': collapsed, 'expanded-content': !collapsed }"
      class="footer-fixed"
    >
      <a-row justify="space-around" align="middle" class="text-white">
        <a-col :span="10" class="pt-2">
          Av. Juan de Arona 755 Piso 3, San Isidro 15046 - Oficinas de WorkTown - Perú<br />
          Razón Social: LimaTours SAC | RUC: 20536830376
        </a-col>
        <a-col :span="6" :offset="6" class="text-right pr-2"
          >Copyright © {{ year }} - All rights reserved
        </a-col>
      </a-row>
    </a-layout-footer>
  </a-layout>
</template>

<script setup>
  import { ref } from 'vue';
  import BHeaderComponent from '@/components/backend/BackendHeaderComponent.vue';
  import BSidebarMenu from '@/components/backend/BackendSidebarMenu.vue';
  import BBreadcrumb from '@/components/backend/BackendBreadcrumbRoutes.vue';

  const year = new Date().getFullYear();
  const collapsed = ref(false);

  const handleCollapseChange = (value) => {
    collapsed.value = value;
    console.log('El estado de collapsed ha cambiado a:', value);
  };
</script>
