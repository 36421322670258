<template>
  <a-spin
    :tip="t('quote.label.please')"
    :spinning="filesStore.isLoading || loadingBreadcrumb || vipsStore.isLoading"
    size="large"
  >
    <a-spin
      class="text-carga"
      :tip="t('quote.label.loading')"
      :spinning="filesStore.isLoading || loadingBreadcrumb || vipsStore.isLoading"
      size="large"
    ></a-spin>
    <a-layout id="files-layout">
      <a-layout-header class="custom-layout-header sticky">
        <header-component />
      </a-layout-header>
      <a-layout class="files-layout-root">
        <a-layout-content>
          <div class="files-layout bg-white h-screen">
            <div class="files-layout-row">
              <div class="files-layout-container mt-4">
                <div class="justify-content-md-center">
                  <template v-if="loadingBreadcrumb">
                    <skeleton active></skeleton>
                  </template>
                  <a-breadcrumb class="breadcrumb-component" v-if="!loadingBreadcrumb">
                    <template #separator>
                      <span class="separator"> / </span>
                    </template>
                  </a-breadcrumb>
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-spin>
</template>

<script setup>
  import merge from 'lodash.merge';
  import { onMounted, watch, ref } from 'vue';
  import ABreadcrumb from '@/components/global/ABreadcrumbRoutes.vue';
  import HeaderComponent from '@/components/global/HeaderComponent.vue';
  import { useI18n } from 'vue-i18n';
  import { useRoute } from 'vue-router';
  import { getUrlAuroraFront } from '@/utils/auth';
  import axios from 'axios';
  import { useLanguagesStore } from '@/stores/global';
  import { useFilesStore, useVipsStore } from '@/stores/files';

  const route = useRoute();
  const languagesStore = useLanguagesStore();
  const filesStore = useFilesStore();
  const vipsStore = useVipsStore();

  const loadingBreadcrumb = ref(false);
  const { getLocaleMessage, locale, mergeLocaleMessage, t } = useI18n({
    useScope: 'global',
  });

  onMounted(async () => {
    let data, iso;
    let file_id = localStorage.getItem('a3_file_id');

    if (file_id > 0) {
      localStorage.removeItem('a3_file_id');
      window.location.href = `${window.url_app}files/${file_id}/edit`;
    } else {
      data = import.meta.glob('../../lang/**/files.json');
      iso = '';
      for (const path in data) {
        data[path]().then((mod) => {
          iso = path.substring(11, 13);
          const messages = {
            files: JSON.parse(JSON.stringify(mod)),
          };

          addTranslations(iso, messages);
        });
      }

      data = import.meta.glob('../../lang/**/quotes.json');
      iso = '';
      for (const path in data) {
        data[path]().then((mod) => {
          iso = path.substring(11, 13);
          const messages = {
            quote: JSON.parse(JSON.stringify(mod)),
          };

          addTranslations(iso, messages);
        });
      }

      getLanguagesFiles();
    }
  });

  const addTranslations = (iso, messages) => {
    let currentMessages = getLocaleMessage(iso);
    const mensajesCombinados = merge({}, currentMessages, messages);
    currentMessages = { ...mensajesCombinados };
    mergeLocaleMessage(iso, currentMessages);
  };

  const getLanguagesFiles = async () => {
    locale.value = languagesStore.getLanguage;

    const files = await axios.get(
      getUrlAuroraFront() + 'translation/' + languagesStore.getLanguage + '/slug/files'
    );

    const quotes = await axios.get(
      getUrlAuroraFront() + 'translation/' + languagesStore.getLanguage + '/slug/quote'
    );

    const messages = {
      files: files.data,
      quote: quotes.data,
    };

    addTranslations(languagesStore.getLanguage, messages);
  };

  watch(route, () => {
    loadingBreadcrumb.value = true;

    setTimeout(() => {
      loadingBreadcrumb.value = false;
    }, 150);
  });

  watch(locale, () => {
    setTimeout(() => {
      getLanguagesFiles();
    }, 150);
  });
</script>

<style lang="scss" scoped>
  :deep(.ant-spin) {
    background: none !important;
    border: 0 !important;
  }

  :deep(.ant-spin-text) {
    padding-top: 60px !important;
    color: #bababa;
    text-shadow: none;
    font-size: 16px;
  }

  :deep(.ant-spin-lg .ant-spin-dot i) {
    display: none;

    &:first-child {
      display: block;
      opacity: 1;
      width: auto;
      height: auto;
    }
  }

  :deep(.ant-spin-dot-spin) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    width: 60px;
    height: 60px;
    animation: rotate 2s infinite ease-in-out;
    overflow: hidden;
    background-color: #c3141a;
    margin: -35px 0 0 -30px !important;
  }

  :deep(.ant-spin-blur) {
    opacity: 0.999;
  }

  :deep(.ant-spin-dot-item) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    background: red url('../../images/quotes/logo.png') 100% 100%;
    background-size: cover;
    animation: rotate 2s infinite ease-in-out;
    border-radius: 0 !important;
  }

  :deep(.ant-spin-container.ant-spin-blur:after) {
    background: rgba(0, 0, 0, 0.95) !important;
    opacity: 1 !important;
    z-index: 25;
  }

  :deep(.ant-spin-text) {
    text-shadow: none !important;
  }

  :deep(.text-carga) {
    z-index: 28 !important;
    display: none !important;
    .ant-spin-text {
      padding-top: 90px !important;
      animation: opacity 1.5s infinite ease-out;
      font-size: 80%;
      font-weight: 400;
      text-shadow: 0 1px 2px #fff !important;
    }
  }

  :deep(.ant-spin-container.ant-spin-blur .text-carga) {
    display: block !important;
  }

  :deep(.text-carga .ant-spin-dot-spin) {
    display: none !important;
  }
</style>
