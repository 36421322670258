<script lang="ts" setup>
  import QuoteNameComponent from '@/quotes/components/info/quote-header/QuoteName.vue';
  import QuoteNewNameComponent from '@/quotes/components/info/quote-header/QuoteNewName.vue';

  import QuoteDateRange from '@/quotes/components/info/quote-header/QuoteDateRange.vue';
  import QuoteNewDateRange from '@/quotes/components/info/quote-header/QuoteNewDateRange.vue';

  import QuotePassengers from '@/quotes/components/info/quote-header/QuotePassengers.vue';
  import QuoteNewPassengers from '@/quotes/components/info/quote-header/QuoteNewPassengers.vue';

  import QuoteRange from '@/quotes/components/info/quote-header/QuoteRange.vue';

  import QuoteOccupation from '@/quotes/components/info/quote-header/QuoteOccupation.vue';
  import QuoteHotelCategories from '@/quotes/components/info/quote-header/QuoteHotelCategories.vue';
  import QuoteNewHotelCategories from '@/quotes/components/info/quote-header/QuoteNewHotelCategories.vue';
  import QuoteServiceCategories from '@/quotes/components/info/quote-header/QuoteServiceCategories.vue';
  import QuoteNewServiceCategories from '@/quotes/components/info/quote-header/QuoteNewServiceCategories.vue';
  import QuoteLanguages from '@/quotes/components/info/quote-header/QuoteLanguages.vue';

  import IconView from '@/quotes/components/icons/IconView.vue';
  import IconCalendars from '@/quotes/components/icons/IconCalendars.vue';

  import { useQuote } from '@/quotes/composables/useQuote';
  import { useI18n } from 'vue-i18n';

  import { provide, ref } from 'vue';

  const sw = ref<boolean>(false);
  provide('show-occupations', sw);
  provide('show-range', sw);

  const { view, operation, quote } = useQuote();
  const { t } = useI18n();

  const changeView = (newView: string) => {
    view.value = newView;
  };
</script>

<template>
  <div class="quotes-info">
    <h3 class="title-red" v-if="quote?.id">
      {{ t('quote.label.modify_package') }}
      <span v-if="quote.file.file_code" style="float: right"
        >{{ t('quote.label.file') }}: {{ quote.file.file_code }}</span
      >
    </h3>
    <h3 class="title-red" v-else>
      {{ t('quote.label.new_quote') }}
    </h3>
    <div class="frame-two">
      <div class="frame-two-left">
        <quote-name-component v-if="quote?.id" />
        <quote-new-name-component v-else />
      </div>
      <div class="frame-two-right" v-if="quote?.id">
        <span class="text">{{ t('quote.label.view') }}:</span>
        <span class="table-icon" @click="changeView('table')">
          <icon-view :class="{ active: view === 'table' }" />
          <!--<font-awesome-icon
            :class="{ active: view === 'table' }"
            icon="table-list"
          />-->
        </span>
        <span class="calendar-icon" @click="changeView('calendar')">
          <icon-calendars :class="{ active: view === 'calendar' }" />
          <!--<font-awesome-icon
            :class="{ active: view === 'calendar' }"
            icon="calendar-days"
          />-->
        </span>
      </div>
    </div>
    <div class="frame-three">
      <div class="container" v-if="quote?.id">
        <quote-date-range />

        <quote-passengers />

        <quote-occupation />

        <quote-hotel-categories />

        <quote-service-categories />

        <quote-languages />
      </div>

      <div class="container" v-else>
        <quote-new-date-range />
        <quote-new-passengers />
        <quote-new-service-categories />
        <quote-new-hotel-categories />
      </div>
    </div>
    <div v-if="operation == 'ranges'" class="frame-three range">
      <quote-range />
    </div>
  </div>
</template>

<style lang="scss">
  @import '@/scss/_variables.scss';

  .quotes-info {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 20px 0;

    .title-red {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 24px;
      line-height: 31px;
      letter-spacing: -0.24px;
      color: #eb5757;
    }

    .btn-edit {
      margin-right: 20px;

      svg {
        cursor: pointer;
        vertical-align: bottom;
      }
    }

    .frame-two {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .frame-two-left {
        display: flex;
        align-items: flex-end;
        gap: 16px;
        flex-shrink: 0;

        .num-coti {
          color: #575757;
          text-align: left;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 17px; /* 121.429% */
          letter-spacing: 0.21px;
        }

        .text {
          .title {
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.48px;
            padding-left: 0;
            color: $color-black;
          }

          input {
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.48px;
            color: $color-black;
            border: 1px solid $color-black-9;
            display: inline-block;
          }

          span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.24px;
            color: $color-black-9;
            display: inline-block;
          }
        }

        .edit {
          cursor: pointer;
          margin-bottom: 6px;
          margin-left: 13px;
          display: inline-block;
          vertical-align: middle;

          .edit-text {
            color: #eb5757;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 25px;
            margin-left: 8px;
          }
        }
      }

      .frame-two-right {
        display: flex;
        padding: 9px 24px;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: #fafafa;
        margin-bottom: 24px;
        color: #bbbdbf;
        font-size: 25px;
        cursor: pointer;

        svg {
          height: 22px;
          width: 22px;
        }

        .active {
          color: #eb5757;

          path {
            fill: #eb5757;
          }
        }

        .calendar-icon {
          padding-left: 4px;
        }

        .text {
          display: flex;
          /*width: 100px;*/
          height: 41px;
          flex-direction: column;
          justify-content: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: -0.18px;
          align-items: flex-end;
          color: $color-black;
        }
      }
    }

    .frame-three {
      display: flex;
      height: 59px;
      padding: 6px 27px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      border-radius: 6px;
      border: 1px solid #e9e9e9;
      background: #ffffff;

      &.range {
        padding: 10px 27px;
        height: auto;
        margin-top: 18px;
      }

      .container {
        display: flex;
        /*height: 34px;*/
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;

        .info-box {
          &.extra {
            .down {
              align-items: center;
              gap: 8px;

              .text {
                display: flex;
                align-items: center;
                gap: 8px;
              }

              .passengers-item {
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                  color: #eb5757;

                  span {
                    color: #eb5757;
                  }
                }
              }

              .item {
                display: flex;
                align-items: center;
                margin-right: 8px;

                &:last-child {
                  margin-right: 0;
                }

                svg {
                  display: flex;
                  width: 16px;
                  height: 16px;
                  justify-content: center;
                  align-items: center;
                }

                &.child {
                  svg {
                    width: auto;
                    height: 17px;
                  }
                }

                span {
                  color: #575757;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 21px;
                  letter-spacing: 0.21px;
                  margin-left: 2px;
                }
              }

              .button-container {
                display: flex;

                svg {
                  display: flex;
                  width: 17px;
                  height: 17px;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                }

                .text {
                  color: #eb5757;
                  text-align: right;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 17px;
                  letter-spacing: 0.18px;
                  cursor: pointer;
                  margin-left: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
