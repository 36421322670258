<script lang="ts" setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.16672 9.58706V13.6676L7.74017 14.5275C7.86387 14.7129 8.13653 14.7129 8.26023 14.5275L8.83342 13.6676V9.58706C8.56284 9.63706 8.28497 9.66675 8.00007 9.66675C7.71517 9.66675 7.4373 9.63706 7.16672 9.58706ZM8.00007 1.33325C5.92894 1.33325 4.25 3.01219 4.25 5.08333C4.25 7.15446 5.92894 8.8334 8.00007 8.8334C10.0712 8.8334 11.7501 7.15446 11.7501 5.08333C11.7501 3.01219 10.0712 1.33325 8.00007 1.33325ZM8.00007 3.31246C7.02349 3.31246 6.22921 4.10674 6.22921 5.08333C6.22921 5.25572 6.0891 5.39583 5.9167 5.39583C5.7443 5.39583 5.60419 5.25572 5.60419 5.08333C5.60419 3.76221 6.67921 2.68745 8.00007 2.68745C8.17247 2.68745 8.31258 2.82755 8.31258 2.99995C8.31258 3.17235 8.17247 3.31246 8.00007 3.31246Z"
      fill="#919191"
    />
  </svg>
</template>

<style lang="scss"></style>
