<script lang="ts" setup>
  import { toRef } from 'vue';

  interface Props {
    color?: string;
    width?: number;
    height?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    color: '#FFCC00',
    width: 24,
    height: 24,
  });

  const color = toRef(props, 'color');
  const width = toRef(props, 'width');
  const height = toRef(props, 'height');
</script>

<template>
  <svg
    fill="none"
    :height="height"
    viewBox="0 0 24 24"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 18.26C12.4896 18.26 12.8866 17.863 12.8866 17.3733C12.8866 16.8836 12.4896 16.4867 11.9999 16.4867C11.5103 16.4867 11.1133 16.8836 11.1133 17.3733C11.1133 17.863 11.5103 18.26 11.9999 18.26Z"
      :fill="color"
    />
    <path
      d="M12.0007 15.0733C11.8238 15.0733 11.6543 15.0031 11.5292 14.878C11.4042 14.753 11.334 14.5834 11.334 14.4066V6.40663C11.334 6.22982 11.4042 6.06025 11.5292 5.93523C11.6543 5.81021 11.8238 5.73997 12.0007 5.73997C12.1775 5.73997 12.347 5.81021 12.4721 5.93523C12.5971 6.06025 12.6673 6.22982 12.6673 6.40663V14.4066C12.6673 14.5834 12.5971 14.753 12.4721 14.878C12.347 15.0031 12.1775 15.0733 12.0007 15.0733Z"
      :fill="color"
    />
    <path
      d="M10.041 1.12069C10.2263 0.783465 10.4988 0.502219 10.83 0.30637C11.1612 0.11052 11.539 0.00726437 11.9237 0.00740065C12.3085 0.00753692 12.6862 0.111061 13.0173 0.307144C13.3483 0.503228 13.6206 0.784667 13.8057 1.12203L23.0657 18.162C23.2433 18.489 23.3329 18.8565 23.3256 19.2284C23.3184 19.6004 23.2146 19.9642 23.0244 20.2839C22.8342 20.6037 22.5642 20.8686 22.2408 21.0526C21.9174 21.2365 21.5517 21.3333 21.1797 21.3334H2.68102C2.30477 21.3418 1.93292 21.2511 1.60275 21.0705C1.27258 20.8899 0.995702 20.6257 0.799866 20.3043C0.60403 19.9829 0.496123 19.6157 0.486964 19.2395C0.477805 18.8632 0.567717 18.4912 0.747683 18.1607L10.041 1.12069ZM1.91968 18.7987C1.851 18.925 1.817 19.0672 1.82116 19.2109C1.82533 19.3546 1.8675 19.4946 1.94338 19.6167C2.01926 19.7388 2.12614 19.8386 2.25314 19.9059C2.38014 19.9732 2.52272 20.0057 2.66635 20H21.1797C21.3207 19.9999 21.4592 19.9632 21.5817 19.8935C21.7042 19.8237 21.8065 19.7233 21.8785 19.6021C21.9505 19.4809 21.9898 19.343 21.9924 19.2021C21.9951 19.0611 21.9611 18.9219 21.8937 18.798L12.6357 1.76136C12.5655 1.63388 12.4624 1.52756 12.3372 1.45349C12.2119 1.37942 12.0691 1.34031 11.9235 1.34024C11.778 1.34017 11.6352 1.37915 11.5098 1.4531C11.3845 1.52705 11.2813 1.63328 11.211 1.76069L1.91902 18.7987H1.91968Z"
      :fill="color"
    />
  </svg>
</template>

<style lang="scss"></style>
