<script lang="ts" setup></script>

<template>
  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7537 7.92638C20.0906 7.92638 21.1752 6.84174 21.1752 5.50486C21.1752 4.16798 20.0906 3.08334 18.7537 3.08334C17.4168 3.08334 16.3322 4.16798 16.3322 5.50486C16.3322 6.84174 17.4168 7.92638 18.7537 7.92638ZM23.5211 15.4482L22.3456 14.8529L21.8563 13.3698C21.1147 11.1198 19.0463 9.54578 16.7005 9.54073C14.8843 9.53569 13.8804 10.0503 11.9936 10.812C10.904 11.2509 10.011 12.0833 9.48635 13.1427L9.14835 13.8288C8.75485 14.6259 9.07268 15.5945 9.86471 15.9931C10.6517 16.3916 11.6102 16.0687 12.0088 15.2717L12.3468 14.5856C12.5233 14.2324 12.8159 13.955 13.1792 13.8087L14.5312 13.2638L13.7644 16.326C13.502 17.3754 13.7846 18.4903 14.5161 19.2924L17.5379 22.5917C17.9011 22.9903 18.1584 23.4695 18.2896 23.9891L19.2128 27.687C19.4297 28.5497 20.3075 29.0794 21.1702 28.8624C22.0329 28.6455 22.5626 27.7677 22.3456 26.905L21.2257 22.4151C21.0945 21.8955 20.8372 21.4112 20.474 21.0177L18.1786 18.5104L19.0463 15.0446L19.3238 15.877C19.5912 16.6893 20.1663 17.3602 20.923 17.7436L22.0984 18.3389C22.8854 18.7375 23.8439 18.4146 24.2425 17.6175C24.6309 16.8255 24.3131 15.8468 23.5211 15.4482ZM11.9735 22.5463C11.812 22.9549 11.5699 23.3232 11.2571 23.631L8.73467 26.1584C8.10407 26.789 8.10407 27.8131 8.73467 28.4437C9.36528 29.0743 10.3843 29.0743 11.0149 28.4437L14.0116 25.4471C14.3193 25.1394 14.5615 24.7711 14.7279 24.3625L15.409 22.6573C12.6192 19.6153 13.4566 20.5486 13.0177 19.9482L11.9735 22.5463Z"
      fill="#919191"
    />
  </svg>
</template>

<style lang="scss"></style>
