<script setup lang="ts"></script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.26125 5.88691H17.7374C18.0662 5.88691 18.3327 5.62043 18.3327 5.29167V3.80357C18.3327 3.47482 18.0662 3.20834 17.7374 3.20834H2.26125C1.9325 3.20834 1.66602 3.47482 1.66602 3.80357V5.29167C1.66602 5.62043 1.9325 5.88691 2.26125 5.88691ZM2.26125 11.8393H17.7374C18.0662 11.8393 18.3327 11.5728 18.3327 11.2441V9.75595C18.3327 9.4272 18.0662 9.16072 17.7374 9.16072H2.26125C1.9325 9.16072 1.66602 9.4272 1.66602 9.75595V11.2441C1.66602 11.5728 1.9325 11.8393 2.26125 11.8393ZM2.26125 17.7917H17.7374C18.0662 17.7917 18.3327 17.5252 18.3327 17.1964V15.7083C18.3327 15.3796 18.0662 15.1131 17.7374 15.1131H2.26125C1.9325 15.1131 1.66602 15.3796 1.66602 15.7083V17.1964C1.66602 17.5252 1.9325 17.7917 2.26125 17.7917Z"
      fill="#B5B5B5"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
