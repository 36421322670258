<template>
  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8183 3.45836L4.32143 7.39467C3.37773 7.74793 2.75195 8.65631 2.75195 9.66563V21.0254C2.75195 21.9439 3.27175 22.7816 4.08929 23.1904L14.5861 28.4388C15.2674 28.782 16.0698 28.782 16.7511 28.4388L27.2479 23.1904C28.0705 22.7816 28.5853 21.9389 28.5853 21.0254V9.66563C28.5853 8.65631 27.9595 7.75298 27.0158 7.39972L16.519 3.46341C15.9739 3.25145 15.3683 3.25145 14.8183 3.45836ZM15.6711 6.59227L25.3605 10.2258V10.2813L15.6711 14.2176L5.98175 10.2813V10.2258L15.6711 6.59227ZM17.286 24.558V17.0437L25.3605 13.7634V20.5208L17.286 24.558Z"
      fill="#000000"
    />
  </svg>
</template>
<script>
  export default {
    name: 'icon-miscellaneous',
  };
</script>
