<template>
  <a-layout>
    <a-result
      status="403"
      title="403"
      sub-title="Sorry, you are not authorized to access this page."
    >
      <template #extra>
        <a-button type="primary" v-on:click="returnToHome()">Back Home</a-button>
      </template>
    </a-result>
  </a-layout>
</template>

<script setup>
  const returnToHome = () => {
    window.location.href = window.url_app;
  };
</script>

<style scoped lang="scss">
  html,
  body,
  #app {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .ant-layout {
    height: 100%;
  }
  .files-layout {
    font-family: var(--files-font-basic);
    background-color: var(--files-background-1);
    min-height: 100vh;
  }
  .files-layout-row {
    width: 100%;
    padding: 0 25px;
  }
  .files-layout-container {
    margin-top: 2rem;
  }
  .custom-layout-header {
    background-color: #fff;
    box-shadow:
      0 3px 6px -2px rgba(0, 0, 0, 0.2),
      0 6px 12px rgba(0, 0, 0, 0.1);
    z-index: 3;
  }
  .breadcrumb-component {
    background: #ffffff;
    border: 0.9px solid #e9e9e9;
    border-radius: 6px;
    padding: 13px 37px;
    gap: 10px;
    margin-bottom: 0.9375rem; // 15px
  }
  .seperator {
    color: #979797;
  }
  .files-layout-root {
    background: white;

    & :deep(.ant-layout-content) {
      background: #fff;
    }
  }
  @media screen and (min-width: 1300px) {
    .files-layout-row {
      display: flex;
      justify-content: center;
    }
  }
</style>
