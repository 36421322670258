<template>
  <div class="files-flights-info">
    <h3 class="files-flights-info-title">Información De Vuelos</h3>

    <a-input-group style="display: flex">
      <a-input v-model:value="url" disabled />
      <a-button type="primary" danger @click="handleClick">
        <span v-if="!copied">Copiar</span>
        <span v-else>Copiado!</span>
      </a-button>
    </a-input-group>

    <hr class="mb-3 w-100 border-0 height-1" style="background-color: #c4c4c4" />

    <h4 class="files-flights-info-title-info">
      Ingrese la información solicitada: FILE {{ route.params.id || '000000' }}
    </h4>

    <a-collapse v-model:activeKey="activeKey" :bordered="false" style="background-color: #fff">
      <template v-for="item in flightsData" :key="item.id">
        <a-collapse-panel :header="item.titulo" :show-arrow="false" :style="customStyle">
          <div>
            <a-checkbox v-model:checked="item.domestico">Doméstico</a-checkbox>
            <a-checkbox v-model:checked="item.internacional">Internacional</a-checkbox>
          </div>
          <a-form :model="formState" layout="vertical" style="margin-top: 20px">
            <a-row :gutter="24">
              <a-col :span="6">
                <a-form-item label="Origen">
                  <a-input placeholder="Origen" v-model:value="item.origen" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Destino">
                  <a-input placeholder="Destino" disabled v-model:value="item.destino" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Fecha">
                  <!-- <a-date-picker placeholder="Fecha" disabled v-model:value="item.fecha" style="width:100%" /> -->
                  <a-input placeholder="Fecha" disabled v-model:value="item.fecha" />
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item label="Salida">
                  <a-date-picker placeholder="Salida" picker="time" />
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item label="Llegada">
                  <a-date-picker placeholder="Llegada" picker="time" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="6">
                <a-form-item label="PNR">
                  <a-input placeholder="PNR"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Aerolínea">
                  <a-input placeholder="Aerolínea"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Número de vuelo">
                  <a-input placeholder="Número de vuelo"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Paxs">
                  <a-input placeholder="Paxs" disabled></a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-collapse-panel>
      </template>
    </a-collapse>
    <hr class="mb-3 w-100 border-0 height-1" style="background-color: #c4c4c4" />
    <div style="display: flex; justify-content: flex-end; margin-top: 20px">
      <a-button type="primary" danger>Guardar</a-button>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import { useRoute } from 'vue-router';
  // import { useClipboard } from '@vueuse/core';

  // const source = ref('');
  // const { text } = useClipboard({ source });

  // const router = useRouter()
  const route = useRoute();

  // const url = ref('')

  const activeKey = ref([]);
  const formState = ref({});

  const customStyle =
    'background: #f7f7f7;border-radius: 6px;margin-bottom: 10px;border: 0;overflow: hidden';

  const flightsData = reactive([
    {
      id: 1,
      titulo: 'Información de vuelos: 20/10/2022',
      domestico: true,
      internacional: false,
      origen: 'Origen',
      destino: 'LIM',
      fecha: '20/10/2022',
      salida: 'Salida',
      llegada: '',
      pnr: '',
      aerolinea: '',
      numVuelo: '',
      paxs: 4,
    },
    {
      id: 2,
      titulo: 'Información de vuelos: 22/10/2022',
      domestico: false,
      internacional: true,
      origen: 'Origen',
      destino: 'LIM',
      fecha: '20/10/2022',
      salida: 'Salida',
      llegada: '',
      pnr: '',
      aerolinea: '',
      numVuelo: '',
      paxs: 4,
    },
    {
      id: 3,
      titulo: 'Información de vuelos: 23/10/2022',
      domestico: false,
      internacional: true,
      origen: 'Origen',
      destino: 'LIM',
      fecha: '20/10/2022',
      salida: 'Salida',
      llegada: '',
      pnr: '',
      aerolinea: '',
      numVuelo: '',
      paxs: 4,
    },
    {
      id: 4,
      titulo: 'Información de vuelos: 25/10/2022',
      domestico: true,
      internacional: false,
      origen: 'Origen',
      destino: 'LIM',
      fecha: '20/10/2022',
      salida: 'Salida',
      llegada: '',
      pnr: '',
      aerolinea: '',
      numVuelo: '',
      paxs: 4,
    },
    {
      id: 5,
      titulo: 'Información de vuelos: 27/10/2022',
      domestico: false,
      internacional: true,
      origen: 'Origen',
      destino: 'LIM',
      fecha: '20/10/2022',
      salida: 'Salida',
      llegada: '',
      pnr: '',
      aerolinea: '',
      numVuelo: '',
      paxs: 4,
    },
  ]);

  // const handleClick = (e) => {
  //   // source.value = 'http://localhost:5173' + route.fullPath
  //   // copy()
  // };

  onMounted(() => {
    // url.value = 'http://localhost:5173' + route.fullPath
  });
</script>

<style scoped lang="scss">
  .files-flights-info {
    min-width: 1040px;
    &-title {
      font-weight: 900;
      padding-bottom: 10px;
    }
    &-title-info {
      color: var(--files-error-dark);
      font-weight: 600;
    }
  }
</style>
