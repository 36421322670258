import dayjs from 'dayjs';

import 'dayjs/locale/es';

const DEFAULT_LOCALE = 'es';

export function formatDate(dateValue, stringFormat = 'DD/MM/YYYY', locale = DEFAULT_LOCALE) {
  if (!dateValue) return '';

  return dayjs(dateValue).locale(locale).format(stringFormat);
}

export function checkDates(date_in, date_out) {
  let date1 = dayjs(date_in);
  let date2 = dayjs(date_out);
  let days = date2.diff(date1, 'days', true);

  return Number(days) + 1;
}

export function getWeekDay(dateValue = '') {
  if (!dateValue) return '';

  const WEEKDAYS = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const indexDay = Number(dayjs(dateValue).format('d'));

  return WEEKDAYS[indexDay];
}

export function truncateString(str, num = 70) {
  if (str.length <= num) return str;
  return str.slice(0, num) + '...';
}

export function formatNumber({
  number,
  currencySymbol = '$',
  digits = 3,
  locales = 'en-US',
  hideCurrencySymbol = true,
}) {
  const OPTIONS = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };

  const numberFormatInstance = new Intl.NumberFormat(locales, OPTIONS);

  const numberValue = Number(number);

  const formatedNumberValue = numberFormatInstance.format(numberValue);

  if (formatedNumberValue)
    return `${!hideCurrencySymbol ? currencySymbol : ''}${formatedNumberValue}`;

  return `${!hideCurrencySymbol ? currencySymbol : ''}${numberValue}`;
}

export function sortArrayByProp(values, prop) {
  return values.sort((a, b) => a[prop] - b[prop]);
}

export function textPad({ text = '', start = '', end = '', length = 0 }) {
  start = String(start);
  end = String(end);

  if (start != '') {
    return String(text).padStart(length, start);
  }

  if (end != '') {
    return String(text).padStart(length, end);
  }
}
