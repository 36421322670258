<template>
  <div :class="props.type" class="button-component">
    <div v-if="props.icon" class="icon">
      <font-awesome-icon :icon="props.icon" />
    </div>
    <slot></slot>
    <div v-if="props.afterIcon" class="icon">
      <font-awesome-icon :icon="props.afterIcon" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  interface Props {
    type?: string;
    icon?: string;
    afterIcon?: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    type: 'normal',
  });
</script>

<style lang="sass">
  .button-component
    border-radius: 6px
    text-align: center
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: -0.255px
    min-width: 170px
    padding: 15px 0
    border: 1px solid #EB5757
    display: flex
    justify-content: center
    gap: 5px

    .icon
      font-size: 17px

    &.normal
      background: #EB5757
      color: #FFF

    &.outline
      background: #FFF
      color: #EB5757

      &:hover
        background: rgba(255, 225, 225, 0.4)
</style>
