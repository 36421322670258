import { defineStore } from 'pinia';
import { fetchClients } from '@service/files';

import { createClientAdapter } from '@store/files/adapters';

export const useClientsStore = defineStore({
  id: 'clients',
  state: () => ({
    loading: false,
    clients: [],
  }),
  getters: {
    isLoading: (state) => state.loading,
    getClients: (state) => state.clients,
  },
  actions: {
    fetchAll(search = '') {
      this.loading = true;
      return fetchClients(search)
        .then(({ data }) => {
          this.clients = Object.entries(data.data.clients).map((e) => createClientAdapter(e));
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
});
