<script lang="ts" setup>
  import { computed, toRef } from 'vue';
  import HotelsEditComponent from './search/HotelsEditComponent.vue';
  import ExtensionEditComponent from './search/ExtensionEditComponent.vue';
  import { useQuote } from '@/quotes/composables/useQuote';
  import type { QuoteService } from '@/quotes/interfaces';
  import ServiceEditComponent from '@/quotes/components/search/ServiceEditComponent.vue';
  import IconClose from '@/quotes/components/icons/IconClose.vue';
  import { useSiderBarStore } from '../store/sidebar';

  const storeSidebar = useSiderBarStore();
  const props = defineProps<{ page: string }>();

  const page = toRef(props, 'page');

  const { serviceSelected, deleteServiceSelected } = useQuote();

  const service = computed(() => serviceSelected.value.service as QuoteService);
  const name = computed(() => {
    if (page.value == 'service_edit') {
      return service.value.service?.service_translations[0].name;
    }
    if (page.value == 'hotel_edit') {
      return service.value.hotel?.name;
    }
    if (page.value == 'extension_edit') {
      return (
        service.value.service?.new_extension.translations[0].name +
        ' ' +
        (service.value.service?.new_extension.nights + 1) +
        'D / ' +
        service.value.service?.new_extension.nights +
        'N'
      );
    }
  });

  const closeSidebar = () => {
    storeSidebar.setStatus(false, '', '');
    deleteServiceSelected();
  };
</script>

<template>
  <div class="sidebar edit">
    <div class="header">
      <h2 :title="name" :class="page">{{ name }}</h2>
      <div class="icon-close-modal">
        <icon-close @click="closeSidebar()" />
      </div>
    </div>
    <div id="bodyPage" class="body">
      <HotelsEditComponent v-if="page == 'hotel_edit'" />
      <ServiceEditComponent v-if="page == 'service_edit'" />
      <ExtensionEditComponent v-if="page == 'extension_edit'" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .icon-close-modal {
    padding: 0;
    font-size: 24px;
    color: #575757;
    &:hover {
      color: #eb5757;
    }
  }

  .sidebar {
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #e9e9e9;
    background: #fafafa;

    .header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #c4c4c4;
      align-items: top;
      padding: 17px;
      /*z-index: 2;
    position: relative;*/

      h2 {
        color: #2e2e2e;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: -0.36px;
        margin-bottom: 0;
        cursor: default;
        max-height: 48px;
        overflow: hidden;
      }

      span {
        color: #eb5757;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 157.143% */
        cursor: pointer;
        letter-spacing: -0.21px;
        text-decoration-line: underline;
      }
    }

    :deep(&.scrollDown) {
      .body .description {
        height: 71vh;
        max-height: 71vh;
      }
    }

    :deep(.body) {
      background: #fefefe;
      .description {
        height: 56.5vh;
        max-height: 56.5vh;
      }
    }

    :deep(.place) {
      display: flex;

      padding: 16px 16px 0 16px;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      justify-content: space-between;
      color: #909090;
      font-size: 18px;
      margin-bottom: 16px;

      div {
        display: flex;
        align-items: center;

        &:last-child {
          font-weight: 700;
        }
      }
    }

    :deep(.quotes-actions-btn) {
      padding: 9px 10px;
      width: 135px;
      height: 44px;

      &.save {
        background: #eb5757;
        color: #fff;
        height: 44px;
        width: 85px;
        .text {
          color: #fff;
        }
      }

      .text {
        display: flex;
        gap: 5px;
        align-items: center;
        letter-spacing: -0.3px;
      }
    }

    :deep(.price) {
      span {
        font-size: 12px;
      }
    }

    &.scrollDown {
      .body {
        height: 74vh;
        max-height: 74vh;
      }
    }
  }

  .scrollDownMaxBottom {
    .scrollDown {
      .body {
        height: 64vh;
        max-height: 64vh;

        :deep(.description) {
          height: auto;
          min-height: 44vh;
        }
      }
    }
  }
</style>
