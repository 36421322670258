<template>
  <div class="header-menu">
    <img class="logo" src="/images/logo_limatours.png" alt="logo-lito" width="180" />
    <div class="header-menu__nav">
      <div class="header-menu__nav-item">
        <a-button ghost :size="'12px'" @click="clickLogout">
          <font-awesome-icon :icon="['fas', 'power-off']" />
          Logout
        </a-button>
      </div>
      <div class="header-menu__nav-item">
        <span class="username"><font-awesome-icon :icon="['fas', 'user']" /> ADMIN </span>
      </div>
      <div class="header-menu__nav-item">
        <a-select class="language-selector" value="es" size="small">
          <template #suffixIcon>
            <font-awesome-icon icon="fa-solid fa-angle-down" size="md" />
          </template>
          <a-select-option value="es">es</a-select-option>
          <a-select-option value="en">en</a-select-option>
          <a-select-option value="pt">pt</a-select-option>
        </a-select>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { logout } from '@/services/auth/servicesAuth.js';
  import { useRouter } from 'vue-router';

  const route = useRouter();

  const clickLogout = async () => {
    await logout();
    route.push('/login');
  };
</script>
