import { notification } from 'ant-design-vue';

const useNotification = () => {
  return {
    // Properties

    // Methods
    showErrorNotification: (message: string) => {
      notification['error']({
        message: 'Error',
        description: message,
      });
    },

    // Getters
  };
};

export default useNotification;
