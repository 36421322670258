import { defineStore } from 'pinia';
// import { handleError } from '@/utils/errorHandling';

import { searchData } from '@service/brevo';

// import { notification } from 'ant-design-vue';

export const useBrevoStore = defineStore({
  id: 'brevo',
  state: () => ({
    // loading
    loading: true,
    notifications: [],
  }),
  getters: {
    isLoaded: (state) => state.loaded,
    isLoading: (state) => state.loading,
    getNotifications: (state) => state.notifications,
  },
  actions: {
    finished() {
      this.loading = false;
    },
    searchNotifications(params) {
      this.loading = true;
      return searchData(params)
        .then(({ data }) => {
          console.log(data);
          this.notifications = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
});
