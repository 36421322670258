<script setup lang="ts"></script>

<template>
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 9L12 15L18 9"
      stroke="#EB5757"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.5"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
