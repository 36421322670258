<script lang="ts" setup>
  import { toRef } from 'vue';
  import { useI18n } from 'vue-i18n';

  const props = defineProps<{ itinerary?: string }>();

  const { t } = useI18n();
  const itinerary = toRef(props, 'itinerary');
  // const { quote } = useQuote();
</script>

<template>
  <div class="detail-header">
    <div class="title">
      <svg
        fill="none"
        height="19"
        viewBox="0 0 18 19"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.25 16.1094L9 12.3594L3.75 16.1094V4.10938C3.75 3.71155 3.90804 3.33002 4.18934 3.04871C4.47064 2.76741 4.85218 2.60938 5.25 2.60938H12.75C13.1478 2.60938 13.5294 2.76741 13.8107 3.04871C14.092 3.33002 14.25 3.71155 14.25 4.10938V16.1094Z"
          stroke="#212529"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
      <span>{{ t('quote.label.itinerary') }}</span>
    </div>
  </div>
  <div class="detail-body">
    <p v-html="itinerary"></p>
  </div>
</template>

<style lang="scss" scoped>
  .detail-header {
    display: flex;
    height: 24px;
    padding-right: 0;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .title {
      display: flex;
      align-items: center;
      gap: 5px;
      flex: 1 0 0;

      span {
        color: #212529;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0.18px;
        text-decoration: underline;
      }
    }
  }

  .detail-body {
    display: flex;
    padding-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    p {
      color: #3d3d3d;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.18px;
      text-align: justify;
    }
  }
</style>
