import { useQuotesStore } from '@/stores/quotes-store';
import { ref } from 'vue';

export const usePopup = () => {
  // TODO move to it own module Packages folder
  const store = useQuotesStore();
  const showForm = ref<boolean>(false);
  const showForm2 = ref<boolean>(false);

  store.$onAction(({ after }) => {
    after(() => {
      showForm.value = false;
      showForm2.value = false;
    });
  });

  return {
    // Properties
    showForm,
    showForm2,
    // Methods

    toggleForm: () => {
      if (showForm.value) {
        store.closeModals();
        showForm.value = false;
      } else {
        store.openModals();
        showForm.value = true;
      }
    },
    toggleForm2: () => {
      if (showForm2.value) {
        store.closeModals();
        showForm2.value = false;
      } else {
        store.openModals();
        showForm2.value = true;
      }
    },

    // Getters
  };
};
