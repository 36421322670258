import request from '../../utils/request';

export function fetchClients(search = '') {
  return request({
    baseURL: window.API,
    method: 'GET',
    url: 'files/clients',
    params: { search },
  });
}
