<template>
  <div class="login">
    <a-row style="height: 100vh" type="flex" justify="space-around" align="middle">
      <a-col :sm="{ span: 10 }">
        <div class="login__card">
          <a-row>
            <img
              class="aurora-logo"
              src="https://aurora.limatours.com.pe/images/logo/logo-aurora.png"
              alt=""
              srcset=""
            />
          </a-row>
          <a-row>
            <a-alert
              :message="message.content"
              :type="message.type"
              v-if="message.type != '' && message.content != ''"
              style="margin-bottom: 1rem; width: 100%; max-width: 100%"
            />
          </a-row>
          <a-form
            :model="formState"
            name="normal_login"
            class="login-form"
            @finish="submit"
            @finishFailed="onFinishFailed"
          >
            <a-form-item
              placeholder="Usuario"
              name="username"
              :rules="[{ required: true, message: 'Please input your username!' }]"
            >
              <a-input v-model:value="formState.username" :readonly="disabled">
                <template #prefix>
                  <UserOutlined class="site-form-item-icon" />
                </template>
              </a-input>
            </a-form-item>

            <a-form-item
              placeholder="Password"
              name="password"
              :rules="[{ required: true, message: 'Please input your password!' }]"
            >
              <a-input-password v-model:value="formState.password" :readonly="disabled">
                <template #prefix>
                  <LockOutlined class="site-form-item-icon" />
                </template>
              </a-input-password>
            </a-form-item>

            <a-form-item>
              <a-button
                :loading="disabled"
                type="primary"
                html-type="submit"
                class="login-form-button"
              >
                Login
              </a-button>
            </a-form-item>
          </a-form>
          <p>
            <a v-bind:href="linkRecover()" class="text-danger">Recuperar contraseña</a>
          </p>
          <a-row>
            <img class="logo" src="/images/logo_limatours.png" alt="logo-lito" />
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted } from 'vue';
  import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
  import { login } from '@service/auth/servicesAuth';
  import { getAccessToken } from '@/utils/auth';
  import router from '@/router';

  const message = reactive({
    type: '',
    content: '',
  });

  const disabled = ref(false);

  const authState = reactive({
    token: null,
  });

  onMounted(() => {
    authState.token = getAccessToken();
  });

  const submit = async (values) => {
    message.type = 'warning';
    message.content = 'Identificando.. Por favor, espere un momento..';
    disabled.value = true;

    const responseLogin = await login(values);

    if (responseLogin.success) {
      router.push({ name: 'home' });
    } else {
      disabled.value = false;
      message.type = 'error';
      message.content = responseLogin.message;
    }
  };

  const linkRecover = () => {
    return window.url_front_a2 + 'password/reset';
  };

  const formState = reactive({
    username: '',
    password: '',
    remember: true,
  });

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
</script>

<style lang="sass" scoped>
  .login
    background: url('https://aurora.limatours.com.pe/images/bg-login.jpg')
    background-size: cover
    .ant-card-body
      padding: 15px 32px 44px
    .ant-form-item
      margin-bottom: 10px
    .ant-input-affix-wrapper
      padding: 6.5px 11px
    .aurora-logo
      width: 100%
      max-width: 236px
      margin: 0 auto 25px
    .logo
      width: 105px
      margin: 10px auto 0
    button
      width: 100%
      height: 52px
      background-color: #EB5757
      border-color: #EB5757
      &:hover
        background-color: #C63838
        border-color: #C63838
        box-shadow: inherit
    &__card
      background: rgba(0,0,0,0.4)
      border: none
      border-radius: 12px
      margin: 0 auto
      width: 300px
      padding: 2rem
</style>
