import { defineStore } from 'pinia';
import { fetchExecutives, fetchBoss } from '../../services/files';

export const useExecutivesStore = defineStore({
  id: 'executives',
  state: () => ({
    loading: false,
    executives: [],
    all_executives: {},
    boss: [],
  }),
  getters: {
    isLoading: (state) => state.loading,
    getExecutives: (state) => state.executives,
    getAllExecutives: (state) => state.all_executives,
    getBoss: (state) => state.boss,
  },
  actions: {
    async fetchAll(search = '') {
      this.loading = true;
      return fetchExecutives('', search)
        .then(({ data }) => {
          this.executives = data.data.executives;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    async findAll(array_codes, search = '') {
      this.loading = true;
      return fetchExecutives(array_codes, search)
        .then(({ data }) => {
          for (const executive of data.data.executives) {
            this.all_executives[executive.code] = executive;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    async fetchAllBoss(array_codes, search = '') {
      this.loading = true;
      return fetchBoss(array_codes, search)
        .then(({ data }) => {
          this.boss = data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
});
