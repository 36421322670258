import request from '../../utils/request';

export function fetchExecutives(array_codes = '', search = '') {
  return request({
    baseURL: window.API,
    method: 'GET',
    url: 'files/executives',
    params: {
      array_codes: array_codes,
      search: search,
    },
  });
}

export function fetchBoss(array_codes, search = '') {
  return request({
    baseURL: window.API,
    method: 'GET',
    url: 'files/boss',
    params: {
      array_codes: array_codes,
      search: search,
    },
  });
}
