import { defineStore } from 'pinia';
import { fetchRevisionStages } from '@service/files';

import { createRevisionStagesAdapter } from '@store/files/adapters';

export const useRevisionStagesStore = defineStore({
  id: 'revisionStages',
  state: () => ({
    loading: false,
    revisionStages: [],
  }),
  getters: {
    isLoading: (state) => state.loading,
    getRevisionStages: (state) => state.revisionStages,
    getRevisionStageById: (state) => (id) => {
      const DEFAULT_REVISION_STAGE = 0;
      if (id === null || id === 0) {
        return state.revisionStages[DEFAULT_REVISION_STAGE] || {};
      }
      return state.revisionStages.find((revisionStage) => {
        return revisionStage.id === id;
      });
    },
  },
  actions: {
    async fetchAll() {
      this.loading = true;
      return fetchRevisionStages()
        .then(({ data }) => {
          this.revisionStages = data.data.map((d) => createRevisionStagesAdapter(d));
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
});
