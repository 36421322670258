<script lang="ts" setup>
  import IconLeftArrowTab from '@/quotes/components/icons/IconLeftArrowTab.vue';
  import TabBodyComponent from '@/quotes/components/details/tabs/TabBodyComponent.vue';
  import { useQuote } from '@/quotes/composables/useQuote';
  import ModalRoomEditDetail from '@/quotes/components/modals/ModalRoomEditDetail.vue';

  const { quoteCategories, selectedCategory } = useQuote();

  const changeTab = (tab: number) => {
    selectedCategory.value = tab;
  };
</script>

<template>
  <div class="quotes-details">
    <div class="quotes-tabs">
      <div class="left">
        <icon-left-arrow-tab />
      </div>
      <div class="center">
        <div
          v-for="quoteCategory of quoteCategories"
          :key="`category-tab-${quoteCategory.type_class_id}`"
          :class="{ active: selectedCategory === quoteCategory.type_class_id }"
          class="tab"
          @click="changeTab(quoteCategory.type_class_id)"
        >
          {{ quoteCategory.type_class.translations[0].value }}
        </div>
      </div>
    </div>
    <div class="quotes-tabs-body">
      <div
        v-for="quoteCategory of quoteCategories"
        :key="`category-tab-body-${quoteCategory.type_class_id}`"
      >
        <tab-body-component
          v-if="selectedCategory === quoteCategory.type_class_id"
          :items="quoteCategory.services"
          :category="quoteCategory.type_class_id"
        />
      </div>
    </div>
  </div>
  <div class="sidebar"></div>

  <modal-room-edit-detail />
</template>

<style lang="scss">
  .quotes-details {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    width: 100%;
    margin-bottom: 24px;

    .quotes-tabs {
      display: flex;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      .left {
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 6px;
        background: #fafafa;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .center {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-end;

        .tab {
          display: flex;
          width: 180px;
          padding: 4px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 6px 6px 0 0;
          background: #e9e9e9;
          color: #979797;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: 0.21px;
          cursor: pointer;

          &.active {
            background: #737373;
            color: #fefefe;
            padding: 6px 16px;
          }
        }
      }
    }

    .quotes-tabs-body {
      width: 100%;
    }
  }
</style>
