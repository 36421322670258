<script setup lang="ts"></script>

<template>
  <svg fill="none" height="32" viewBox="0 0 31 32" width="31" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.2064 9.54166H26.8027V7.11979C26.8027 4.85937 21.7975 3.08333 15.5007 3.08333C9.20378 3.08333 4.19857 4.85937 4.19857 7.11979V9.54166H3.79492C3.12638 9.54166 2.58398 10.0836 2.58398 10.7526V14.7891C2.58398 15.4576 3.12638 16 3.79492 16H4.19857V24.0729C4.19857 24.9645 4.9216 25.6875 5.81315 25.6875V27.3021C5.81315 28.1936 6.53618 28.9167 7.42773 28.9167H9.04232C9.93387 28.9167 10.6569 28.1936 10.6569 27.3021V25.6875H20.3444V27.3021C20.3444 28.1936 21.0674 28.9167 21.959 28.9167H23.5736C24.4651 28.9167 25.1882 28.1936 25.1882 27.3021V25.6875H25.5111C26.3184 25.6875 26.8027 25.0417 26.8027 24.3958V16H27.2064C27.8749 16 28.4173 15.4576 28.4173 14.7891V10.7526C28.4173 10.0836 27.8749 9.54166 27.2064 9.54166ZM10.6569 6.71614C10.6569 6.49313 10.8375 6.31249 11.0605 6.31249H19.9408C20.1638 6.31249 20.3444 6.49313 20.3444 6.71614V7.52343C20.3444 7.74645 20.1638 7.92708 19.9408 7.92708H11.0605C10.8375 7.92708 10.6569 7.74645 10.6569 7.52343V6.71614ZM8.23503 23.2656C7.34347 23.2656 6.62044 22.5426 6.62044 21.651C6.62044 20.7595 7.34347 20.0365 8.23503 20.0365C9.12658 20.0365 9.84961 20.7595 9.84961 21.651C9.84961 22.5426 9.12658 23.2656 8.23503 23.2656ZM14.6934 17.6146H9.04232C8.15076 17.6146 7.42773 16.8915 7.42773 16V11.1562C7.42773 10.2647 8.15076 9.54166 9.04232 9.54166H14.6934V17.6146ZM16.3079 17.6146V9.54166H21.959C22.8505 9.54166 23.5736 10.2647 23.5736 11.1562V16C23.5736 16.8915 22.8505 17.6146 21.959 17.6146H16.3079ZM22.7663 23.2656C21.8747 23.2656 21.1517 22.5426 21.1517 21.651C21.1517 20.7595 21.8747 20.0365 22.7663 20.0365C23.6578 20.0365 24.3809 20.7595 24.3809 21.651C24.3809 22.5426 23.6578 23.2656 22.7663 23.2656Z"
      fill="#3D3D3D"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
