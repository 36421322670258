import DashboardLayout from '@views/backend/BackendDashboardLayout.vue';
import auth from '@/middleware/auth.js';

const ROUTE_NAME = 'negotiations';

export default {
  path: `/${ROUTE_NAME}`,
  name: ROUTE_NAME,
  component: DashboardLayout,
  // redirect:  `/${ROUTE_NAME}/dashboard`,
  meta: {
    middleware: auth, // Validaciones de sesión..
    breadcrumb: 'Negociaciones',
  },
  children: [
    {
      path: 'accounting-management',
      name: 'accounting-management',
      meta: {
        breadcrumb: 'Contabilidad',
      },
      children: [
        {
          path: 'tax',
          name: 'tax',
          component: () =>
            import(
              '@/modules/negotiations/accounting-management/tax-settings/layout/TaxSettingsLayout.vue'
            ),
          meta: {
            breadcrumb: 'Configurador contable',
          },
          children: [
            {
              path: 'general',
              name: 'taxGeneral',
              component: () =>
                import(
                  '@/modules/negotiations/accounting-management/tax-settings/general/pages/GeneralTax.vue'
                ),
              meta: {
                breadcrumb: 'Configuración de IGV',
              },
            },
            {
              path: 'suppliers',
              name: 'taxSuppliersLayout',
              component: () =>
                import(
                  '@/modules/negotiations/accounting-management/tax-settings/suppliers/layout/SupplierTaxLayout.vue'
                ),
              meta: {
                breadcrumb: 'Proveedor',
              },
              children: [
                {
                  path: 'configuration',
                  name: 'taxSuppliers',
                  component: () =>
                    import(
                      '@/modules/negotiations/accounting-management/tax-settings/suppliers/pages/SuppliersTax.vue'
                    ),
                  meta: {
                    breadcrumb: 'IGV por tipo de proveedor',
                  },
                },
                {
                  path: 'configuration/:id/assignments',
                  name: 'taxSuppliersAssignments',
                  component: () =>
                    import(
                      '@/modules/negotiations/accounting-management/tax-settings/suppliers/pages/SuppliersTaxAssignments.vue'
                    ),
                  meta: {
                    breadcrumb: 'Asignación de IGV',
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'financial-expenses',
          name: 'financialExpenses',
          component: () =>
            import(
              '@/modules/negotiations/accounting-management/financial-expenses/layout/FinancialExpensesLayout.vue'
            ),
          meta: {
            breadcrumb: 'Gastos Financieros',
          },
          children: [
            {
              path: 'general',
              name: 'financialExpensesGeneral',
              component: () =>
                import(
                  '@/modules/negotiations/accounting-management/financial-expenses/pages/FinancialExpensesGeneral.vue'
                ),
              meta: {
                breadcrumb: 'Listado de gastos financieros',
              },
            },
          ],
        },
        {
          path: 'exchange-rates',
          name: 'exchangeRates',
          component: () =>
            import(
              '@/modules/negotiations/accounting-management/exchange-rates/layout/ExchangeRatesLayout.vue'
            ),
          meta: {
            breadcrumb: 'Tipo de cambio estimado',
          },
          children: [
            {
              path: 'general',
              name: 'exchangeRatesGeneral',
              component: () =>
                import(
                  '@/modules/negotiations/accounting-management/exchange-rates/pages/ExchangeRatesGeneral.vue'
                ),
              meta: {
                breadcrumb: 'Tipo de cambio estimado',
              },
            },
          ],
        },
        {
          path: 'cost-sale-accounts',
          name: 'costSaleAccounts',
          component: () =>
            import(
              '@/modules/negotiations/accounting-management/cost-sale-accounts/layout/CostSaleAccountsLayout.vue'
            ),
          meta: {
            breadcrumb: 'Cuenta costos y ventas por clasificación',
          },
          children: [
            {
              path: 'general',
              name: 'costSaleAccountsGeneral',
              component: () =>
                import(
                  '@/modules/negotiations/accounting-management/cost-sale-accounts/pages/CostSaleAccountsGeneral.vue'
                ),
              meta: {
                breadcrumb: 'Cuenta costos y ventas',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'suppliers',
      name: 'suppliers',
      component: () => import('@/modules/negotiations/supplier/layout/SupplierLayout.vue'),
      meta: {
        breadcrumb: 'Proveedores',
      },
      children: [
        {
          path: 'land',
          name: 'suppliersLand',
          meta: {
            breadcrumb: 'Terrestre',
          },
          redirect: { name: 'suppliersTouristTransportList' },
          children: [
            {
              path: 'tourist-transports',
              name: 'suppliersTouristTransports',
              meta: {
                breadcrumb: 'Transportes turísticos',
              },
              redirect: { name: 'suppliersTouristTransportList' },
              children: [
                {
                  path: 'list',
                  name: 'suppliersTouristTransportList',
                  component: () =>
                    import(
                      '@/modules/negotiations/supplier/land/tourist-transport/pages/TouristTransportListPage.vue'
                    ),
                  meta: {
                    breadcrumb: 'Listado',
                  },
                },
                // {
                //   path: 'register',
                //   name: 'suppliersTouristTransportList',
                //   component: () =>
                //     import(
                //       '@/modules/negotiations/supplier/land/tourist-transport/pages/TouristTransportListPage.vue'
                //     ),
                //   meta: {
                //     breadcrumb: 'Listado',
                //   },
                // },
              ],
            },
            {
              path: 'tourist-trains',
              name: 'suppliersTouristTrains',
              meta: {
                breadcrumb: 'Trenes turísticos',
              },
              redirect: { name: 'suppliersTouristTrainList' },
              children: [
                {
                  path: 'list',
                  name: 'suppliersTouristTrainList',
                  component: () =>
                    import(
                      '@/modules/negotiations/supplier/land/tourist-train/pages/TouristTrainListPage.vue'
                    ),
                  meta: {
                    breadcrumb: 'Listado',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'transport-configurator',
      name: 'transportConfigurator',
      component: () =>
        import(
          '@/modules/negotiations/transport-configurator/layout/TransportConfiguratorLayout.vue'
        ),
      meta: {
        breadcrumb: 'Configurador',
      },
      redirect: { name: 'transportConfiguratorGeneral' },
      children: [
        {
          path: 'general',
          name: 'transportConfiguratorGeneral',
          component: () =>
            import(
              '@/modules/negotiations/transport-configurator/pages/TransportConfiguratorGeneral.vue'
            ),
          meta: {
            breadcrumb: 'Tipos de unidades de transporte',
          },
        },
      ],
    },
    {
      path: 'country-calendar',
      name: 'countryCalendar',
      meta: {
        breadcrumb: 'Calendarios de países',
      },
      children: [
        {
          path: 'general',
          component: () =>
            import(
              '@/modules/negotiations/country-calendar/general/layout/CountryCalendarGeneralLayout.vue'
            ),
          meta: {
            breadcrumb: 'Calendarios',
          },
          children: [
            {
              path: '',
              name: 'countryCalendarGeneral',
              component: () =>
                import(
                  '@/modules/negotiations/country-calendar/general/pages/CountryCalendarGeneral.vue'
                ),
            },
          ],
        },
        {
          path: 'configuration/:id',
          component: () =>
            import(
              '@/modules/negotiations/country-calendar/configuration/layout/HolidayCalendarConfigurationLayout.vue'
            ),
          meta: {
            breadcrumb: 'Configuración',
          },
          children: [
            {
              path: '',
              name: 'holidayCalendarConfiguration',
              component: () =>
                import(
                  '@/modules/negotiations/country-calendar/configuration/pages/HolidayCalendarConfiguration.vue'
                ),
            },
          ],
        },
      ],
    },
  ],
};
