// import { DashboardView } from '@/components/files/DashboardView.vue';
// 1. Define route components.
import { createRouter, createWebHistory } from 'vue-router';

import auth from '@/middleware/auth';
import isLogin from '@/middleware/isLogin';

import LoginView from '@views/LoginView.vue';
import AccessDeniedView from '@views/AccessDeniedView.vue';
import RegisterPaxsView from '@views/RegisterPaxsView.vue';
import RegisterFlightsView from '@views/RegisterFlightsView.vue';
// import DashboardLayout from '@views/BackendDashboardLayout.vue';
import TemporalLayout from '@views/TemporalLayout.vue';
// import VerticalLayout from '@/layouts/VerticalLayout.vue';

// refactorizar todo lo de abajo

// import { isAuthenticated } from '../utils/auth'
import operationRoutes from './operation';
import negotiationRoutes from './negotiation';
import iconRoutes from './icons';
import filesRoutes from './files';
import brevoRoutes from './brevo';
import { quotesRoutes } from '@/quotes/router';

// 2. Define some routes
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: TemporalLayout,
      // component:VerticalLayout,
      meta: {
        middleware: auth,
        breadcrumb: 'Home',
      },
      children: [],
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: {
        middleware: isLogin,
      },
    },
    filesRoutes,
    brevoRoutes,
    operationRoutes,
    negotiationRoutes,
    iconRoutes,
    {
      ...quotesRoutes,
      path: '/quotes',
      meta: {
        middleware: auth,
        breadcrumb: 'Home',
      },
    },
    {
      path: '/access-denied',
      name: 'error_403',
      meta: {
        middleware: auth,
      },
      component: AccessDeniedView,
    },
    {
      path: '/register_paxs/:nrofile',
      name: 'register_paxs',
      component: RegisterPaxsView,
    },
    {
      path: '/register_flights/:nrofile',
      name: 'register_flights',
      component: RegisterFlightsView,
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
    // refactor
    // {
    //   path: "/aurora-dashboard",
    //   name: "aurora-dashboard",
    //   component: AuroraDashboard,

    // },
  ],
  mode: 'history',
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
