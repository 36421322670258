<script setup lang="ts"></script>

<template>
  <svg fill="none" height="12" viewBox="0 0 13 12" width="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.37479 5.10714H1.63504C1.48712 5.10714 1.36719 5.22707 1.36719 5.375V6.62499C1.36719 6.77292 1.48712 6.89285 1.63504 6.89285H8.37479V7.92095C8.37479 8.39823 8.95182 8.63725 9.28932 8.29977L11.2103 6.37881C11.4195 6.16959 11.4195 5.8304 11.2103 5.6212L9.28932 3.70024C8.95184 3.36277 8.37479 3.60179 8.37479 4.07906V5.10714Z"
      fill="#C4C4C4"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
