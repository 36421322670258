<script lang="ts" setup></script>

<template>
  <svg fill="none" height="32" viewBox="0 0 31 32" width="31" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9178 7.92605C19.2547 7.92605 20.3393 6.84141 20.3393 5.50453C20.3393 4.16765 19.2547 3.08301 17.9178 3.08301C16.5809 3.08301 15.4963 4.16765 15.4963 5.50453C15.4963 6.84141 16.5809 7.92605 17.9178 7.92605ZM22.6851 15.4479L21.5097 14.8526L21.0203 13.3694C20.2788 11.1194 18.2104 9.54544 15.8645 9.54039C14.0484 9.53535 13.0445 10.0499 11.1577 10.8117C10.068 11.2506 9.17508 12.083 8.65041 13.1424L8.31241 13.8285C7.91891 14.6256 8.23674 15.5942 9.02878 15.9927C9.81577 16.3913 10.7743 16.0684 11.1728 15.2713L11.5108 14.5852C11.6874 14.2321 11.98 13.9546 12.3432 13.8083L13.6952 13.2635L12.9284 16.3257C12.6661 17.375 12.9486 18.4899 13.6801 19.2921L16.702 22.5914C17.0652 22.9899 17.3225 23.4692 17.4536 23.9888L18.3769 27.6867C18.5938 28.5493 19.4716 29.079 20.3342 28.8621C21.1969 28.6452 21.7266 27.7674 21.5097 26.9047L20.3897 22.4148C20.2586 21.8952 20.0013 21.4109 19.6381 21.0174L17.3427 18.5101L18.2104 15.0443L18.4878 15.8767C18.7552 16.6889 19.3303 17.3599 20.0871 17.7433L21.2625 18.3386C22.0495 18.7371 23.008 18.4143 23.4066 17.6172C23.795 16.8251 23.4772 15.8464 22.6851 15.4479ZM11.1375 22.546C10.9761 22.9546 10.7339 23.3229 10.4212 23.6306L7.89873 26.1581C7.26813 26.7887 7.26813 27.8128 7.89873 28.4434C8.52934 29.074 9.54839 29.074 10.179 28.4434L13.1756 25.4468C13.4834 25.139 13.7255 24.7707 13.892 24.3621L14.573 22.657C11.7833 19.6149 12.6207 20.5482 12.1818 19.9479L11.1375 22.546Z"
      fill="#3D3D3D"
    />
  </svg>
</template>

<style lang="scss"></style>
