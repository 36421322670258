export default function checkPermission(to, from, next) {
  // Aquí debes implementar la lógica para verificar los permisos del usuario

  const subject = to.meta.permission; // Suponiendo que has definido una meta propiedad en tus rutas para especificar los permisos requeridos
  const action = to.meta.action;

  let permissions = JSON.parse(localStorage.getItem('permissions'));
  let permission = permissions.filter((option) => option.subject === subject);

  if (permission[0] != undefined) {
    let options = permission[0].actions.filter((option) => option === action);
    if (options.length > 0) {
      next();
    } else {
      next('/access-denied');
    }
  } else {
    next('/access-denied');
  }
}
