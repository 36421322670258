<script lang="ts" setup></script>

<template>
  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.0404 9.54168H27.6367V7.1198C27.6367 4.85939 22.6315 3.08334 16.3346 3.08334C10.0378 3.08334 5.03255 4.85939 5.03255 7.1198V9.54168H4.62891C3.96037 9.54168 3.41797 10.0836 3.41797 10.7526V14.7891C3.41797 15.4576 3.96037 16 4.62891 16H5.03255V24.0729C5.03255 24.9645 5.75558 25.6875 6.64714 25.6875V27.3021C6.64714 28.1936 7.37017 28.9167 8.26172 28.9167H9.8763C10.7679 28.9167 11.4909 28.1936 11.4909 27.3021V25.6875H21.1784V27.3021C21.1784 28.1936 21.9014 28.9167 22.793 28.9167H24.4076C25.2991 28.9167 26.0221 28.1936 26.0221 27.3021V25.6875H26.3451C27.1523 25.6875 27.6367 25.0417 27.6367 24.3958V16H28.0404C28.7089 16 29.2513 15.4576 29.2513 14.7891V10.7526C29.2513 10.0836 28.7089 9.54168 28.0404 9.54168ZM11.4909 6.71616C11.4909 6.49314 11.6715 6.31251 11.8945 6.31251H20.7747C20.9978 6.31251 21.1784 6.49314 21.1784 6.71616V7.52345C21.1784 7.74646 20.9978 7.92709 20.7747 7.92709H11.8945C11.6715 7.92709 11.4909 7.74646 11.4909 7.52345V6.71616ZM9.06901 23.2656C8.17746 23.2656 7.45443 22.5426 7.45443 21.6511C7.45443 20.7595 8.17746 20.0365 9.06901 20.0365C9.96056 20.0365 10.6836 20.7595 10.6836 21.6511C10.6836 22.5426 9.96056 23.2656 9.06901 23.2656ZM15.5273 17.6146H9.8763C8.98475 17.6146 8.26172 16.8916 8.26172 16V11.1563C8.26172 10.2647 8.98475 9.54168 9.8763 9.54168H15.5273V17.6146ZM17.1419 17.6146V9.54168H22.793C23.6845 9.54168 24.4076 10.2647 24.4076 11.1563V16C24.4076 16.8916 23.6845 17.6146 22.793 17.6146H17.1419ZM23.6003 23.2656C22.7087 23.2656 21.9857 22.5426 21.9857 21.6511C21.9857 20.7595 22.7087 20.0365 23.6003 20.0365C24.4918 20.0365 25.2148 20.7595 25.2148 21.6511C25.2148 22.5426 24.4918 23.2656 23.6003 23.2656Z"
      fill="#919191"
    />
  </svg>
</template>

<style lang="scss"></style>
