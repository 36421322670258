import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const usePermissionStore = defineStore('permissions', () => {
  const routes = useStorage('routes', []);
  const permissions = useStorage('permissions', []);
  const token = useStorage('__token', null);

  function setRoutes(records) {
    routes.value = records;
  }

  function setPermissions(records) {
    permissions.value = records;
  }

  function getPermissions() {
    return permissions.value;
  }

  const setToken = () => (token.value = 'tokendeprueba');

  return {
    routes,
    setRoutes,
    setPermissions,
    getPermissions,
    setToken,
  };
});
