import { notification } from 'ant-design-vue';

export interface response {
  success: boolean;
  data: object;
  error: string;
  code: number;
}

interface ValidationErrors {
  [key: string]: string[];
}

interface HttpError {
  response?: {
    status: number;
    data: {
      data: ValidationErrors;
      error?: string | null;
    };
  };
  message?: string;
}

const title: string = 'Aurora';

export const handleSuccessResponse = (response?: response) => {
  console.log(response);
  notification.success({
    message: title,
    description: 'Los datos se han guardado correctamente.',
  });
};

export const handleError = (error: HttpError) => {
  if (error.response && error.response.status === 422) {
    const validationErrors = error.response.data.data;
    handleValidationErrors(validationErrors);
  } else if (error.response && error.response.status === 400) {
    notification.error({
      message: title,
      description: 'Error al procesar la solicitud: ' + error.response.data.error ?? error,
    });
  } else {
    notification.error({
      message: title,
      description: 'Error al procesar la solicitud:' + error,
    });
    console.error('', error);
  }
};

const handleValidationErrors = (validationResponse?: ValidationErrors) => {
  if (validationResponse) {
    Object.values(validationResponse).forEach((errors) => {
      if (Array.isArray(errors)) {
        errors.forEach((error) => {
          notification.warning({
            message: title,
            description: error,
          });
        });
      }
    });
  }
};

export default { handleSuccessResponse, handleError };
