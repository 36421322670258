<template>
  <popover-hover-and-click
    :data="data"
    @onPopoverClick="handlePopoverClickSetVip({ fileVips: data.vips })"
    @onCancel="handleSetVipCancel"
    :editable="editable"
    @onSave="handleSetVipSave({ fileId: data.id, vipsSaved: data.vips })"
    :hide-content-hover="data.vips.length === 0"
  >
    <template #default="{ isVip }">
      <span class="group-vip">
        <font-awesome-icon v-if="isVip" class="is-vip" icon="fa-solid fa-star" />
        <font-awesome-icon v-else icon="fa-regular fa-star" />
      </span>
    </template>
    <template #content-hover="{ vips }">
      <span v-if="vips.length > 0">
        <strong>Motivos asociados:</strong>
        <!-- <div v-for="vip in vips" :key="vip.id">{{ vip.vipName }}</div> -->
        <div v-if="vips.length > 0">{{ vips.at(-1).vipName }}</div>
      </span>
      <template v-else>&nbsp;</template>
    </template>
    <template #content-click="{ fileNumber }">
      <div class="vip-content-click" v-if="editable">
        <div class="vip-content-click-title">FILE {{ fileNumber }} - Establecer VIP</div>
        <a-form class="form-vip" :model="formVip">
          <base-select
            style="width: 100%"
            name="vipSelected"
            label="Escoge un motivo"
            placeholder="Selecciona"
            size="large"
            :disabled="formVip.vipHasAnotherReason"
            :allowClear="true"
            :loading="vipsStore.isLoading"
            :comma="false"
            :options="vipsStore.getCustomVips"
            v-model:value="formVip.vipSelected"
          />
          <a-form-item :colon="false" style="margin-bottom: 0">
            <template #label>
              <a-checkbox v-model:checked="formVip.vipHasAnotherReason"
                >Otro motivo&nbsp;&nbsp;</a-checkbox
              >
            </template>
            <base-input
              name="vipAnotherReason"
              placeholder="Especificar motivo"
              size="large"
              width="210"
              :disabled="!formVip.vipHasAnotherReason"
              v-model="formVip.vipAnotherReason"
            />
          </a-form-item>
        </a-form>
      </div>
    </template>
  </popover-hover-and-click>
</template>

<script setup>
  import { reactive } from 'vue';

  import { useVipsStore } from '@store/files';

  import PopoverHoverAndClick from '@/components/files/reusables/PopoverHoverAndClick.vue';
  import BaseSelect from '@/components/files/reusables/BaseSelect.vue';
  import BaseInput from '@/components/files/reusables/BaseInput.vue';
  import { notification } from 'ant-design-vue';

  const vipsStore = useVipsStore();

  const DEFAULT_FORM_VIP = {
    vipSelected: null,
    vipTemp: null,
    vipHasAnotherReason: false,
    vipAnotherReason: '',
  };

  const formVip = reactive(DEFAULT_FORM_VIP);

  const emit = defineEmits(['onRefreshCache']);

  const props = defineProps({
    data: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: () => true,
    },
  });

  const handlePopoverClickSetVip = ({ fileVips }) => {
    if (props.editable) {
      const DEFAULT_VIP_INDEX = -1;
      formVip.value = DEFAULT_FORM_VIP;
      formVip.vipHasAnotherReason = false;
      const vip = fileVips?.at(DEFAULT_VIP_INDEX);
      formVip.vipSelected = vip?.vipId;
      formVip.vipTemp = vip?.vipId;
    }
  };

  const handleSetVipCancel = () => {
    // console.log('handleSetVipCancel...')
  };

  const handleSetVipSave = ({ fileId, vipsSaved }) => {
    const EMPTY_VIP = undefined;

    const isNew = formVip.vipTemp === EMPTY_VIP;
    const isUpdate = !isNew;
    const isDelete = !formVip.vipSelected && Boolean(fileId);

    const vipId = formVip.vipSelected;

    if (formVip.vipHasAnotherReason) {
      const vipName = formVip.vipAnotherReason;
      vipsStore
        .createVipRelated({ fileId, vipName })
        .then((response) => {
          if (!response.error) {
            let newVip = {
              created_at: '',
              deleted_at: '',
              entity: 'file',
              updated_at: '',
              vipName: vipName,
              id: 0,
            };
            /* eslint-disable vue/no-mutating-props */
            props.data.isVip = true;
            props.data.vips = [newVip];

            emit('onRefreshCache');
            formVip.vipAnotherReason = '';
            notification['success']({
              message: `FILE ${fileId} - Establecer VIP`,
              description: 'Se guardó correctamente',
              duration: 5,
            });
          }
        })
        .catch(() => {
          notification['error']({
            message: `FILE ${fileId} - Establecer VIP`,
            description: 'No se guardó VIP',
            duration: 8,
          });
        });
      return;
    }

    if (isDelete) {
      const vipRelated = vipsSaved.find((vip) => vip.vipId === formVip.vipTemp);

      if (!vipRelated) return;

      vipsStore
        .removeVipAndFileRelated({ fileId, vipId: vipRelated.vipId })
        .then((response) => {
          if (!response.error) {
            props.data.isVip = false;
            props.data.vips = [];

            // emit('onRefreshCache')
            notification['success']({
              message: `FILE ${fileId} - Remover VIP`,
              description: 'Se removió VIP',
              duration: 5,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          notification['error']({
            message: `FILE ${fileId} - Remover VIP`,
            description: 'No se removió VIP',
            duration: 8,
          });
        });
      return;
    }

    if (isNew) {
      vipsStore
        .addVipAndFileRelated({ fileId, vipId })
        .then((response) => {
          formVip.value = DEFAULT_FORM_VIP;
          if (!response.error) {
            let newVip = {};
            let vips = vipsStore.getCustomVips;

            vips.forEach((_vip) => {
              if (_vip.value == vipId) {
                newVip = {
                  created_at: '',
                  deleted_at: '',
                  entity: 'file',
                  updated_at: '',
                  vipName: _vip.label,
                  id: _vip.value,
                };
              }
            });

            props.data.isVip = true;
            props.data.vips = [newVip];

            // emit('onRefreshCache')
            notification['success']({
              message: `FILE ${fileId} - Establecer VIP`,
              description: 'Se guardó correctamente',
              duration: 5,
            });
          }
        })
        .catch(() => {
          notification['error']({
            message: `FILE ${fileId} - Establecer VIP`,
            description: 'No se guardó VIP',
            duration: 8,
          });
        });
      return;
    }

    if (isUpdate) {
      const vipRelated = vipsSaved.find((vip) => vip.vipId === formVip.vipTemp);

      if (!vipRelated) return;

      const vipsId = vipRelated.id;
      const vipId = formVip.vipSelected;

      vipsStore
        .changeVipAndFileRelated({ fileId, vipsId, vipId })
        .then((response) => {
          if (!response.error) {
            // emit('onRefreshCache')
            formVip.value = DEFAULT_FORM_VIP;
            return;
          }
        })
        .catch(() => {
          notification['error']({
            message: `FILE ${fileId} - Establecer VIP`,
            description: 'No se guardó VIP',
            duration: 8,
          });
        });
    }
  };
</script>

<style scoped lang="scss">
  .form-vip {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    margin-bottom: 1.625rem;
  }
  .group-vip {
    cursor: pointer;
    transition: 0.3s ease all;
    .is-vip {
      color: var(--files-exclusives);
    }

    &:hover {
      color: var(--files-exclusives);
    }
  }
  .vip-content-click {
    font-family: var(--files-font-basic);
    width: 410px;
    height: 260px;
    // height: 337px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 3px;
    &-title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 23px;
      padding: 20px 12px 23px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #3d3d3d;
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 1.8125rem;
    }
  }
</style>
