<script lang="ts" setup>
  interface Props {
    color?: string;
    width?: number;
    height?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    color: '#BBBDBF',
    width: 20,
    height: 18,
  });
</script>

<template>
  <svg
    :width="props.width"
    :height="props.height"
    :viewBox="`0 0 ${props.width} ${props.height}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.125 17.75H1.875C0.839453 17.75 0 16.9105 0 15.875V2.125C0 1.08945 0.839453 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08945 20 2.125V15.875C20 16.9105 19.1605 17.75 18.125 17.75ZM5 3.6875C4.13707 3.6875 3.4375 4.38707 3.4375 5.25C3.4375 6.11293 4.13707 6.8125 5 6.8125C5.86293 6.8125 6.5625 6.11293 6.5625 5.25C6.5625 4.38707 5.86293 3.6875 5 3.6875ZM5 7.4375C4.13707 7.4375 3.4375 8.13707 3.4375 9C3.4375 9.86293 4.13707 10.5625 5 10.5625C5.86293 10.5625 6.5625 9.86293 6.5625 9C6.5625 8.13707 5.86293 7.4375 5 7.4375ZM5 11.1875C4.13707 11.1875 3.4375 11.8871 3.4375 12.75C3.4375 13.6129 4.13707 14.3125 5 14.3125C5.86293 14.3125 6.5625 13.6129 6.5625 12.75C6.5625 11.8871 5.86293 11.1875 5 11.1875ZM16.25 5.875V4.625C16.25 4.36613 16.0401 4.15625 15.7812 4.15625H7.96875C7.70988 4.15625 7.5 4.36613 7.5 4.625V5.875C7.5 6.13387 7.70988 6.34375 7.96875 6.34375H15.7812C16.0401 6.34375 16.25 6.13387 16.25 5.875ZM16.25 9.625V8.375C16.25 8.11613 16.0401 7.90625 15.7812 7.90625H7.96875C7.70988 7.90625 7.5 8.11613 7.5 8.375V9.625C7.5 9.88387 7.70988 10.0938 7.96875 10.0938H15.7812C16.0401 10.0938 16.25 9.88387 16.25 9.625ZM16.25 13.375V12.125C16.25 11.8661 16.0401 11.6562 15.7812 11.6562H7.96875C7.70988 11.6562 7.5 11.8661 7.5 12.125V13.375C7.5 13.6339 7.70988 13.8438 7.96875 13.8438H15.7812C16.0401 13.8438 16.25 13.6339 16.25 13.375Z"
      :fill="props.color"
    />
  </svg>
</template>

<style lang="scss"></style>
