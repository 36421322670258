<script lang="ts" setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
    <path
      d="M13.4154 2.41675H5.7487C5.24037 2.41675 4.75285 2.61868 4.39341 2.97813C4.03397 3.33757 3.83203 3.82508 3.83203 4.33341V19.6667C3.83203 20.1751 4.03397 20.6626 4.39341 21.022C4.75285 21.3815 5.24037 21.5834 5.7487 21.5834H17.2487C17.757 21.5834 18.2445 21.3815 18.604 21.022C18.9634 20.6626 19.1654 20.1751 19.1654 19.6667V8.16675L13.4154 2.41675Z"
      stroke="#EB5757"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.418 2.41675V8.16675H19.168"
      stroke="#EB5757"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3346 12.9583H7.66797"
      stroke="#EB5757"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3346 16.7917H7.66797"
      stroke="#EB5757"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.58464 9.125H8.6263H7.66797"
      stroke="#EB5757"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style lang="scss"></style>
