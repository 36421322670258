<script lang="ts" setup>
  interface Props {
    color?: string;
    width?: number;
    height?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    color: '#BBBDBF',
    width: 18,
    height: 20,
  });
</script>

<template>
  <svg
    :width="props.width"
    :height="props.height"
    :viewBox="`0 0 ${props.width} ${props.height}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.03125 11.25H4.46875C4.21094 11.25 4 11.0391 4 10.7812V9.21875C4 8.96094 4.21094 8.75 4.46875 8.75H6.03125C6.28906 8.75 6.5 8.96094 6.5 9.21875V10.7812C6.5 11.0391 6.28906 11.25 6.03125 11.25ZM10.25 10.7812V9.21875C10.25 8.96094 10.0391 8.75 9.78125 8.75H8.21875C7.96094 8.75 7.75 8.96094 7.75 9.21875V10.7812C7.75 11.0391 7.96094 11.25 8.21875 11.25H9.78125C10.0391 11.25 10.25 11.0391 10.25 10.7812ZM14 10.7812V9.21875C14 8.96094 13.7891 8.75 13.5312 8.75H11.9688C11.7109 8.75 11.5 8.96094 11.5 9.21875V10.7812C11.5 11.0391 11.7109 11.25 11.9688 11.25H13.5312C13.7891 11.25 14 11.0391 14 10.7812ZM10.25 14.5312V12.9688C10.25 12.7109 10.0391 12.5 9.78125 12.5H8.21875C7.96094 12.5 7.75 12.7109 7.75 12.9688V14.5312C7.75 14.7891 7.96094 15 8.21875 15H9.78125C10.0391 15 10.25 14.7891 10.25 14.5312ZM6.5 14.5312V12.9688C6.5 12.7109 6.28906 12.5 6.03125 12.5H4.46875C4.21094 12.5 4 12.7109 4 12.9688V14.5312C4 14.7891 4.21094 15 4.46875 15H6.03125C6.28906 15 6.5 14.7891 6.5 14.5312ZM14 14.5312V12.9688C14 12.7109 13.7891 12.5 13.5312 12.5H11.9688C11.7109 12.5 11.5 12.7109 11.5 12.9688V14.5312C11.5 14.7891 11.7109 15 11.9688 15H13.5312C13.7891 15 14 14.7891 14 14.5312ZM17.75 4.375V18.125C17.75 19.1602 16.9102 20 15.875 20H2.125C1.08984 20 0.25 19.1602 0.25 18.125V4.375C0.25 3.33984 1.08984 2.5 2.125 2.5H4V0.46875C4 0.210938 4.21094 0 4.46875 0H6.03125C6.28906 0 6.5 0.210938 6.5 0.46875V2.5H11.5V0.46875C11.5 0.210938 11.7109 0 11.9688 0H13.5312C13.7891 0 14 0.210938 14 0.46875V2.5H15.875C16.9102 2.5 17.75 3.33984 17.75 4.375ZM15.875 17.8906V6.25H2.125V17.8906C2.125 18.0195 2.23047 18.125 2.35938 18.125H15.6406C15.7695 18.125 15.875 18.0195 15.875 17.8906Z"
      :fill="props.color"
    />
  </svg>
</template>

<style lang="scss"></style>
