import axios from 'axios';
import {
  getAccessToken,
  setAccessToken,
  setAccessTokenCognito,
  setUserEmail,
  removeCookiesCross,
  setUserCode,
  setUserId,
  setUserType,
  setUserName,
  setUserClientId,
  setUserDepartmentId,
  setUserDepartmentName,
  setUserDepartmentTeamId,
  setUserDepartmentTeamName,
} from '../../utils/auth';
import { usePermissionStore } from '@store/permission-store';

const HTTP_OK = 200;
const baseURL = () => `${window.url_back_a2}api`;
const bearerToken = (access_token) => `Bearer ${access_token}`;

async function getRoutes(access_token) {
  setAccessToken(access_token);
  const { data } = await axios.get(`${baseURL()}/menu`, {
    headers: {
      Authorization: bearerToken(access_token),
    },
  });

  return data.data;
}

async function login_a2({ username, password }) {
  const permissionStore = usePermissionStore();

  const response_a2 = await axios.post(`${window.url_back_a2}api/login`, {
    code: username,
    password: password,
  });

  if (response_a2.status && response_a2.status == HTTP_OK) {
    const { access_token, permissions } = response_a2.data;
    permissionStore.setPermissions(permissions);
    const routes = await getRoutes(access_token);
    permissionStore.setRoutes(routes);
  }
}

export async function login({ username, password }) {
  try {
    const response = await axios.post(`${window.url_auth_cognito}auth/login`, {
      sync: true,
      username: username,
      password: password,
    });

    if (response.status && response.status == HTTP_OK) {
      const { access_token } = response.data;

      const user_cognito = await axios.get(`${window.url_auth_cognito}auth/me`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      const { code, email, user_type_id, department, id, name, client_seller } = user_cognito.data;

      setAccessTokenCognito(access_token);
      setUserEmail(email);
      setUserCode(code);
      setUserType(user_type_id);
      setUserId(id);
      setUserName(name);
      if (department && department.length > 0) {
        setUserDepartmentId(department.id);
        setUserDepartmentName(department.name);
        setUserDepartmentTeamId(department.team.id);
        setUserDepartmentTeamName(department.team.name);
      }

      if (client_seller != null) {
        if (user_type_id == 4) {
          if (client_seller.client_id == '' || client_seller.client_id <= 0) {
            return {
              success: false,
              message: 'Hubo un error al iniciar sesión. Por favor, revise sus credenciales',
            };
          }
          setUserClientId(client_seller.client_id);
        } else {
          setUserClientId(client_seller.client_id);
        }
      }

      await login_a2({
        username: username,
        password: password,
      });

      return {
        success: true,
      };
    }

    return {
      success: false,
      message: 'Hubo un error al iniciar sesión. Por favor, revise sus credenciales',
    };
  } catch (e) {
    return {
      success: false,
      message: e.response.status == 401 ? e.response.data.error : '',
    };
  }
}

export async function logout() {
  let response = true;
  const permissionStore = usePermissionStore();
  try {
    await axios.post(
      `${window.url_back_a2}api/logout`,
      {},
      {
        headers: {
          Authorization: bearerToken(getAccessToken()),
        },
      }
    );
  } catch (e) {
    console.error(e.response.status);
    response = false;
  } finally {
    removeCookiesCross();
    permissionStore.setPermissions([]);
    permissionStore.setRoutes([]);
    response = false;
  }
  return response;
}
