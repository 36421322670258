<script lang="ts" setup>
  import { toRef } from 'vue';

  interface Props {
    color?: string;
    width?: number;
    height?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    color: '#3D3D3D',
    width: 36,
    height: 37,
  });

  const color = toRef(props, 'color');
  const width = toRef(props, 'width');
  const height = toRef(props, 'height');
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 36 37"
    fill="none"
  >
    <path
      d="M32.592 29.437L26.7497 23.5958C26.486 23.3321 26.1286 23.1856 25.7536 23.1856H24.7985C26.4157 21.1173 27.3766 18.5163 27.3766 15.6863C27.3766 8.95446 21.921 3.5 15.1886 3.5C8.45622 3.5 3 8.95446 3 15.6863C3 22.4181 8.45564 27.8725 15.1886 27.8725C18.0187 27.8725 20.6208 26.9116 22.6892 25.2944V26.2495C22.6892 26.6245 22.8356 26.9819 23.0993 27.2456L28.9417 33.0868C29.4924 33.6375 30.3831 33.6375 30.928 33.0868L32.5862 31.4286C33.137 30.8778 33.137 29.9878 32.592 29.437ZM15.1886 24.1226C10.5281 24.1226 6.75115 20.3526 6.75115 15.6863C6.75115 11.0263 10.5216 7.24998 15.1886 7.24998C19.8491 7.24998 23.6261 11.0199 23.6261 15.6863C23.6261 20.3462 19.8556 24.1226 15.1886 24.1226ZM16.7771 15.1847L14.1398 14.3937C13.8374 14.3029 13.6259 13.9964 13.6259 13.6478C13.6259 13.2218 13.9365 12.875 14.3173 12.875H15.9644C16.2316 12.875 16.4894 12.9505 16.7156 13.0929C16.9054 13.2119 17.1468 13.2048 17.3091 13.0501L17.9976 12.3933C18.2044 12.1959 18.1927 11.8537 17.9642 11.682C17.431 11.2818 16.7876 11.0509 16.1261 11.017V10.0625C16.1261 9.80348 15.9163 9.59372 15.6574 9.59372H14.7199C14.4609 9.59372 14.2511 9.80348 14.2511 10.0625V11.007C12.8666 11.0439 11.7503 12.2111 11.7503 13.6478C11.7503 14.8179 12.5115 15.8632 13.6007 16.1902L16.238 16.9812C16.5404 17.072 16.7519 17.3784 16.7519 17.7271C16.7519 18.1531 16.4413 18.4999 16.0605 18.4999H14.414C14.1468 18.4999 13.889 18.4243 13.6628 18.282C13.473 18.163 13.2316 18.17 13.0693 18.3247L12.3808 18.9816C12.174 19.179 12.1857 19.5212 12.4142 19.6929C12.9474 20.0931 13.5908 20.3239 14.2523 20.3579V21.3124C14.2523 21.5714 14.4621 21.7812 14.721 21.7812H15.6585C15.9175 21.7812 16.1273 21.5714 16.1273 21.3124V20.3679C17.5118 20.331 18.628 19.1644 18.628 17.7271C18.628 16.557 17.8669 15.5117 16.7771 15.1847Z"
      :fill="color"
    />
  </svg>
</template>

<style lang="scss" scoped></style>
