<script lang="ts" setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
    <path
      d="M16.5734 18.3999L16.574 18.4003C16.7369 18.518 16.9636 18.4823 17.0822 18.3227L17.0828 18.3219L17.8488 17.2773L17.8489 17.2773L17.8506 17.2748C17.9651 17.1119 17.9339 16.8836 17.7665 16.7663C17.7661 16.766 17.7657 16.7657 17.7652 16.7654L14.01 14.0503V7.49749C14.01 7.29679 13.8464 7.1332 13.6457 7.1332H12.3574C12.1567 7.1332 11.9931 7.29679 11.9931 7.49749V14.8743C11.9931 14.9905 12.047 15.1007 12.1436 15.1699C12.1438 15.1701 12.144 15.1703 12.1443 15.1704L16.5734 18.3999ZM13 1.35C6.29037 1.35 0.85 6.79037 0.85 13.5C0.85 20.2096 6.29037 25.65 13 25.65C19.7096 25.65 25.15 20.2096 25.15 13.5C25.15 6.79037 19.7096 1.35 13 1.35ZM13 23.3143C7.58106 23.3143 3.18571 18.9189 3.18571 13.5C3.18571 8.08106 7.58106 3.68571 13 3.68571C18.4189 3.68571 22.8143 8.08106 22.8143 13.5C22.8143 18.9189 18.4189 23.3143 13 23.3143Z"
      fill="#212529"
      stroke="#212529"
      stroke-width="0.3"
      stroke-linecap="round"
    />
  </svg>
</template>

<style lang="scss"></style>
