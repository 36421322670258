<template>
  <div class="button-download-container">
    <ButtonComponent
      after-icon="chevron-down"
      class="button-download"
      :text="t('quote.label.download')"
      type="outline"
      @click="toggleForm"
      >{{ t('quote.label.download') }}
    </ButtonComponent>
    <div v-if="showForm" :class="{ openDownload: showForm }" class="box">
      <DropDownSelectComponent
        :items="props.items"
        :multi="false"
        @click="toggleForm"
        @selected="selectedItem"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
  import DropDownSelectComponent from '@/quotes/components/global/DropDownSelectComponent.vue';
  import { reactive } from 'vue';
  import ButtonComponent from '@/quotes/components/global/ButtonComponent.vue';
  import { usePopup } from '@/quotes/composables/usePopup';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const { showForm, toggleForm } = usePopup();

  // const { quote } = useQuote();

  interface downloadItem {
    label: string;
    value: string;
  }

  interface downloadItems {
    [key: string]: downloadItem[];
  }

  interface Props {
    items: downloadItems;
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['selected']);

  const state = reactive({
    isOpen: false,
  });

  const selectedItem = (item: downloadItem) => {
    state.isOpen = false;
    emit('selected', item);
  };
</script>

<style lang="sass">
  .button-download-container
    position: relative

    .box
      position: absolute
      display: none

      &.openDownload
        display: block
        z-index: 2
</style>
