import request from '../../utils/requestAurora';

export function fetchLanguages() {
  return request({
    baseURL: window.url_back_a2,
    method: 'GET',
    url: 'api/languages',
    params: {},
  });
}

export function fetchAllLanguages() {
  return request({
    baseURL: window.url_back_a2,
    method: 'GET',
    url: 'api/languages/all',
    params: {},
  });
}
