<template>
  <a-row type="flex" justify="space-between" align="middle" style="gap: 10px">
    <a-col class="d-flex">
      <a-button @click="handleCloseModify()" class="text-600" danger type="primary" size="large">
        <svg
          class="d-block"
          xmlns="http://www.w3.org/2000/svg"
          width="1.2em"
          height="1.2em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M21 11H6.414l5.293-5.293l-1.414-1.414L2.586 12l7.707 7.707l1.414-1.414L6.414 13H21z"
          />
        </svg>
      </a-button>
    </a-col>
    <a-col flex="auto">
      <span> Modificación de Pasajeros </span>
    </a-col>
    <a-col class="d-flex" style="gap: 0.5rem">
      <a-button
        v-if="!readonly"
        class="text-600"
        danger
        size="large"
        v-bind:disabled="selected.length == 0"
        @click="handleRemove()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="d-block"
          width="1.2rem"
          height="1.2rem"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
          viewBox="0 0 24 24"
        >
          <path
            d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"
          />
        </svg>
      </a-button>
      <template v-if="flagSimulation">
        <a-button
          class="text-600"
          v-bind:disabled="filesStore.isLoadingAsync"
          danger
          size="large"
          v-if="readonly"
          @click="readonly = !readonly"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="d-block"
            width="1.2rem"
            height="1.2rem"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-5m-1.414-9.414a2 2 0 1 1 2.828 2.828L11.828 15H9v-2.828z"
            />
          </svg>
        </a-button>
        <a-button
          class="text-600"
          v-bind:disabled="filesStore.isLoadingAsync"
          danger
          size="large"
          v-if="!readonly"
          @click="handleSave()"
        >
          <i class="bi bi-floppy"></i>
        </a-button>
        <a-button
          v-if="!readonly"
          class="text-600"
          v-bind:disabled="filesStore.isLoadingAsync"
          danger
          @click="handleAddPassenger()"
          size="large"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="d-block"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M19 8v6m3-3h-6" />
            </g>
          </svg>
        </a-button>
      </template>
    </a-col>
  </a-row>

  <div class="mt-3">
    <a-table
      size="small"
      :showHeader="true"
      :columns="columns"
      :pagination="false"
      :data-source="passengersTemp"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex == 'id'">
          <template v-if="!readonly">
            <span class="cursor-pointer" v-on:click="togglePax(record.id)">
              <i
                :class="[
                  'bi',
                  selected.indexOf(record.id) > -1 ? 'bi-check-square text-danger' : 'bi-square',
                ]"
                style="font-size: 1rem"
              ></i>
            </span>
          </template>
          <template v-else> - </template>
        </template>
        <template v-if="column.dataIndex === 'room_type'">
          <template v-if="readonly">
            <strong>{{
              record.room_type != undefined && record.room_type != '' && record.room_type != null
                ? t(filesStore.showRoomType(record.room_type))
                : '-'
            }}</strong>
          </template>
          <template v-else>
            <a-select
              v-model:value="record.room_type"
              style="width: 120px"
              :options="rooms"
            ></a-select>
          </template>
        </template>
        <template v-if="column.dataIndex === 'accommodation'">
          <template v-if="record.room_type > 1">
            <template v-if="readonly">
              <template v-for="pax in record.accommodation">
                <a-tag>{{ pax.name }} {{ pax.surnames }}</a-tag>
              </template>
            </template>
            <template v-else>
              <a-select
                mode="tags"
                id="passengers"
                style="width: 100%"
                v-model:value="accommodations[record.file_passenger_id]"
                max-tag-count="responsive"
                :field-names="{ label: 'label', value: 'file_passenger_id' }"
                @change="(value) => updatePassengerAccommodation(record, value)"
                :options="options[record.file_passenger_id]"
              >
              </a-select>
            </template>
          </template>
        </template>
        <template v-if="column.dataIndex === 'price_pax'">
          <strong>{{ record.type }}</strong>
        </template>
      </template>
    </a-table>
  </div>

  <div class="mt-3" v-if="flagSimulation">
    <template v-if="step == 0">
      <simulation-service-list
        :quantity_adults="quantity_adults"
        :quantity_children="quantity_children"
        v-if="flagSimulationProcess"
      >
      </simulation-service-list>

      <template v-if="!flagSimulationProcess">
        <a-alert type="info" class="mb-3">
          <template #description>
            <a-row type="flex" justify="start" align="top" style="gap: 10px">
              <a-col>
                <i class="bi bi-exclamation-circle" style="font-size: 18px"></i>
              </a-col>
              <a-col>
                <p class="text-700 mb-1 p-0">Error en la simulación</p>
                La simulación no encontró hoteles disponibles en las fechas actuales. Por lo tanto
                no se puede completar la simulación.
              </a-col>
            </a-row>
          </template>
        </a-alert>
      </template>

      <div class="box-buttons">
        <a-row type="flex" justify="end" align="middle">
          <a-col>
            <a-button
              type="default"
              class="mx-2 px-4 text-600"
              v-on:click="handleA2()"
              default
              :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
              size="large"
            >
              Ir a cotizar
            </a-button>
            <a-button
              type="primary"
              class="mx-2 px-4 text-600"
              v-on:click="nextStep()"
              default
              v-if="filesStore.getSimulations.length > 0"
              :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
              size="large"
            >
              Actualizar el File
            </a-button>
          </a-col>
        </a-row>
      </div>
    </template>

    <template v-if="step == 1">
      <template v-if="filesStore.getSimulations.length > 0">
        <template
          v-for="(_simulation, s) in filesStore.getSimulations"
          :key="'item-simulation-' + i"
        >
          <hotel-merge
            v-if="_simulation.type == 'hotel'"
            type="new"
            :filter="_simulation.params"
            :show_communication="true"
            :flag_simulation="true"
            :title="false"
            :to="[_simulation.hotel]"
            :buttons="false"
            ref="items"
          />

          <service-merge
            type="new"
            v-if="_simulation.type == 'service'"
            :filter="_simulation.params"
            :flag_simulation="true"
            :show_communication="true"
            :title="false"
            :to="[_simulation.service]"
            :buttons="false"
            ref="items"
          />
        </template>
      </template>
    </template>
  </div>

  <div class="mt-3" v-else>
    <a-alert type="info" class="mb-3">
      <template #description>
        <a-row type="flex" justify="start" align="top" style="gap: 10px">
          <a-col>
            <i class="bi bi-exclamation-circle" style="font-size: 18px"></i>
          </a-col>
          <a-col>
            <p class="text-700 mb-1 p-0">Error en la simulación</p>
            La simulación no puede procesarse porque la fecha del FILE es pasada. La recomendación
            es cotizar nuevamente los servicios.
          </a-col>
        </a-row>
      </template>
    </a-alert>

    <div class="box-buttons">
      <a-row type="flex" justify="end" align="middle">
        <a-col>
          <a-button
            type="default"
            class="mx-2 px-4 text-600"
            v-on:click="handleA2()"
            default
            :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            Ir a cotizar
          </a-button>
        </a-col>
      </a-row>
    </div>
  </div>

  <a-modal v-model:visible="modal_add" title="Nuevo Pasajero" :width="400">
    <a-alert
      class="text-warning"
      type="warning"
      show-icon
      description="Al agregar nuevos pasajeros entrarás en el simulador de costos."
    >
    </a-alert>

    <div id="files-layout">
      <div class="d-block w-100 mt-3">
        <a-row type="flex" justify="space-around">
          <div class="d-flex">
            <div class="passengers-group">
              <div class="passenger-input">
                <span class="cursor-pointer" @click="flag_adult = !flag_adult">
                  <i
                    :class="['bi', flag_adult ? 'bi-check-square-fill text-danger' : 'bi-square']"
                    style="font-size: 1.5rem"
                  ></i>
                </span>
                <span class="passenger-label cursor-pointer" @click="flag_adult = !flag_adult"
                  >Adultos</span
                >
                <template v-if="flag_adult">
                  <a-input
                    v-model="form.adult"
                    :value="form.adult"
                    min="0"
                    class="passenger-value border-gray-500"
                  />
                  <div class="passenger-controls">
                    <a-button @click="() => increment('adult')" class="passenger-button">
                      <template #icon><font-awesome-icon :icon="['fas', 'chevron-up']" /></template>
                    </a-button>
                    <a-button @click="() => decrement('adult')" class="passenger-button">
                      <template #icon
                        ><font-awesome-icon :icon="['fas', 'chevron-down']"
                      /></template>
                    </a-button>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="passengers-group">
              <div class="passenger-input">
                <span class="cursor-pointer" @click="flag_child = !flag_child">
                  <i
                    :class="['bi', flag_child ? 'bi-check-square-fill text-danger' : 'bi-square']"
                    style="font-size: 1.5rem"
                  ></i>
                </span>
                <span class="passenger-label cursor-pointer" @click="flag_child = !flag_child"
                  >Niños</span
                >
                <template v-if="flag_child">
                  <a-input
                    v-model="form.children"
                    :value="form.children"
                    min="0"
                    class="passenger-value border-gray-500"
                  />
                  <div class="passenger-controls">
                    <a-button @click="() => increment('children')" class="passenger-button">
                      <template #icon><font-awesome-icon :icon="['fas', 'chevron-up']" /></template>
                    </a-button>
                    <a-button @click="() => decrement('children')" class="passenger-button">
                      <template #icon
                        ><font-awesome-icon :icon="['fas', 'chevron-down']"
                      /></template>
                    </a-button>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </a-row>
      </div>

      <div class="d-block w-100 mt-3">
        <div class="bg-light p-4" v-if="flag_child && form.children > 0">
          <span class="d-block text-500 mb-2" style="font-size: 12px">
            Fecha de nacimiento: <b class="text-danger text-700" style="font-size: 14px">*</b>
          </span>

          <a-form :label-col="{ style: { width: '100px' } }" :wrapper-col="{ span: 14 }">
            <template v-for="count in form.children">
              <a-form-item>
                <template #label> Niño #{{ count }} </template>
                <a-date-picker
                  size="large"
                  value-format="YYYY-MM-DD"
                  :disabledDate="disabledDate"
                  v-model:value="form.dates[count - 1]"
                />
              </a-form-item>
            </template>
          </a-form>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="text-center">
        <a-button
          type="default"
          default
          @click="handleCancel"
          v-bind:disabled="filesStore.isLoading || filesStore.isLoadingAsync"
          size="large"
        >
          Cancelar
        </a-button>
        <a-button
          type="primary"
          primary
          :loading="loading"
          @click="handleProcessAdd"
          v-bind:disabled="filesStore.isLoading || filesStore.isLoadingAsync"
          size="large"
        >
          Continuar
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { notification } from 'ant-design-vue';
  import { debounce } from 'lodash-es';
  import { useFilesStore } from '@/stores/files';
  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  import SimulationServiceList from '../reusables/SimulationServiceList.vue';
  import HotelMerge from '../reusables/HotelMerge.vue';
  import ServiceMerge from '../reusables/ServiceMerge.vue';
  import Cookies from 'js-cookie';

  const { t } = useI18n({
    useScope: 'global',
  });

  const filesStore = useFilesStore();
  const emit = defineEmits(['onCloseModify']);

  const modal_add = ref(false);
  const flag_adult = ref(false);
  const flag_child = ref(false);
  const selected = ref([]);
  const readonly = ref(true);
  const form = ref({
    adult: 0,
    children: 0,
    dates: [],
  });
  const passengersTemp = ref([]);

  const items = ref(null);

  const rooms = [
    { label: 'SIMPLE', value: '1' },
    { label: 'DOBLE', value: '2' },
    { label: 'TRIPLE', value: '3' },
  ];

  const handleCloseModify = () => {
    emit('onCloseModify');
  };

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      sorter: false,
    },
    {
      title: 'Tipo de pasajero',
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Apellido',
      dataIndex: 'surnames',
      sorter: true,
    },
    {
      title: 'Tipo de habitación',
      dataIndex: 'room_type',
      sorter: true,
    },
    {
      title: 'Acomodo',
      dataIndex: 'accommodation',
      sorter: true,
    },
    {
      title: 'Fecha de nacimiento',
      dataIndex: 'date_birth',
      sorter: true,
    },
    {
      title: 'Costo por pax',
      dataIndex: 'cost_by_passenger',
      sorter: true,
    },
  ];

  const handleAddPassenger = () => {
    modal_add.value = true;
  };

  const handleCancel = () => {
    modal_add.value = false;
  };

  const paxMax = ref(0);
  const accommodationMax = ref(0);

  const handleProcessAdd = async () => {
    const adults = parseInt(form.value.adult || 0);
    const children = parseInt(form.value.children || 0);
    const total = adults + children;

    for (let item = 1; item <= total; item++) {
      accommodationMax.value++;
      paxMax.value++;

      let id = accommodationMax.value;
      let file_id_passenger = paxMax.value;
      let type = item <= adults ? 'ADL' : 'CHD';
      let name = 'PAX';
      let surname = `${type} #${item}`;

      let passenger = {
        id: id,
        file_passenger_id: file_id_passenger,
        name: name,
        surnames: surname,
        date_birth: type == 'CHD' ? form.value.dates[item - adults] : null,
        type: type,
        room_type: '1',
        accommodation: [],
        cost_by_passenger: '-',
        label: `${name} ${surname}`,
      };

      passengersTemp.value.push(passenger);
      validateAccommodations();
    }

    handleCancel();
  };

  const handleRemove = () => {
    passengersTemp.value = passengersTemp.value.filter(
      (passenger) => !selected.value.includes(passenger.id)
    );

    setTimeout(() => {
      selected.value = [];
    }, 10);
  };

  const quantity_adults = ref(0);
  const quantity_children = ref(0);

  const handleSave = () => {
    readonly.value = true;

    if (filesStore.getFile.dateIn <= dayjs().format('YYYY-MM-DD')) {
      return false;
    }

    let count_sgl = 0;
    let count_dbl = 0;
    let count_tpl = 0;

    passengersTemp.value.forEach((passenger) => {
      if (passenger.type == 'ADL') {
        quantity_adults.value += 1;
      }

      if (passenger.type == 'CHD') {
        quantity_children.value += 1;
      }

      if (passenger.room_type == 1) {
        count_sgl += 1;
      }

      if (passenger.room_type == 2) {
        count_dbl += 1;
      }

      if (passenger.room_type == 3) {
        count_tpl += 1;
      }
    });

    console.log(count_sgl, count_dbl, count_tpl);
    console.log('Itineraries: ', filesStore.getFileItineraries);

    flagSimulationProcess.value = true;
  };

  const togglePax = (_pax) => {
    let index = selected.value.indexOf(_pax);
    if (index > -1) {
      selected.value.splice(index, 1);
    } else {
      selected.value.push(_pax);
    }
  };

  const accommodations = ref({});
  const options = ref({});
  // const paxsIgnored = ref([]);

  const increment = (type) => {
    const currentValue = parseInt(form.value[type], 10) || 0;
    updatePassenger(type, currentValue + 1);
  };

  const decrement = (type) => {
    const currentValue = parseInt(form.value[type], 10) || 0;
    updatePassenger(type, currentValue - 1);
  };

  const updatePassenger = (type, currentValue) => {
    if (currentValue >= 0) {
      form.value[type] = currentValue;
    }
  };

  const flagSimulation = ref(true);
  const flagSimulationProcess = ref(false);

  const updatePassengerAccommodation = debounce((record, value) => {
    localStorage.setItem('accommodation_previous', JSON.stringify(record.accommodation));
    record.accommodation = value;

    if (!(record.accommodation.length <= record.room_type - 1 && record.type == 'ADL')) {
      const accomodation_previous = JSON.parse(localStorage.getItem('accommodation_previous'));
      record.accommodation = accomodation_previous;
      accommodations.value[record.file_passenger_id] = accomodation_previous;

      notification.error({
        message: 'Error de asignación',
        description: `No está permitida la asignación seleccionada. Por favor, intente nuevamente.`,
      });

      localStorage.removeItem('accommodation_previous');
      return;
    }
  }, 350);

  const validateAccommodations = async () => {
    accommodations.value = {};
    options.value = {};

    for (const record of passengersTemp.value) {
      accommodations.value[record.file_passenger_id] = record.accommodation.map((pax) => pax.id);
      options.value[record.file_passenger_id] = passengersTemp.value.filter(
        (pax) => pax.file_passenger_id !== record.file_passenger_id
      );
    }
  };

  onBeforeMount(async () => {
    await filesStore.fetchModifyPaxs();
    passengersTemp.value = filesStore.getModifyPaxs;

    passengersTemp.value.forEach((pax) => {
      paxMax.value = pax.file_passenger_id > paxMax.value ? pax.file_passenger_id : paxMax.value;
      accommodationMax.value = pax.id > accommodationMax.value ? pax.id : accommodationMax.value;
    });

    validateAccommodations();

    flagSimulation.value = disabledDate(new Date());
  });

  const disabledDate = (current) => {
    return current && current < dayjs(filesStore.getFile.dateIn, 'YYYY-MM-DD');
  };

  const handleA2 = () => {
    Cookies.set('a3_client_code', filesStore.getFile.clientCode, { domain: window.DOMAIN });
    Cookies.set('a3_client_id', filesStore.getFile.clientId, { domain: window.DOMAIN });

    localStorage.setItem('a3_file_id', filesStore.getFile.id);
    window.location.href = window.url_app + 'quotes';
  };

  const step = ref(0);

  const nextStep = () => {
    step.value++;
  };
</script>
