import { ref } from 'vue';

import type { QuoteHotelCategory, QuoteHotelCategoryListResponse } from '@/quotes/interfaces';
import quotesApi from '@/quotes/api/quotesApi';
// import { getLang } from "@/quotes/helpers/get-lang";
import useQuoteTranslations from '@/quotes/composables/useQuoteTranslations';

// import useQuoteTranslations  from "@/quotes/composables/useQuoteTranslations";

// const { getLang } = useQuoteTranslations()

const quoteHotelCategories = ref<QuoteHotelCategory[]>([]);
const isLoading = ref(true);
const getQuoteHotelCategories = async (lang: string): Promise<QuoteHotelCategory[]> => {
  const { data } = await quotesApi.get<QuoteHotelCategoryListResponse>(
    `/api/typeclass/quotes/selectbox?lang=${lang}&type=2`
  );

  return data.data.map((item) => {
    return {
      value: item.id.toString(),
      label: item.translations[0].value,
      selected: item.checked as boolean,
    };
  });
};

export const useQuoteHotelCategories = () => {
  const { getLang } = useQuoteTranslations();
  return {
    // Props
    quoteHotelCategories,
    isLoading,
    // Methods
    getQuoteHotelCategories: async () => {
      isLoading.value = true;
      quoteHotelCategories.value = await getQuoteHotelCategories(getLang());
      isLoading.value = false;
    },

    // Getters
  };
};
