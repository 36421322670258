<template>
  <span class="files-switch-categories" v-if="!isEditing">
    <div v-if="selectedCategories.length === 0">
      <a-switch size="small" v-model:checked="isEditing" :disabled="!isEditable" />
      <span
        @click="isEditing = !isEditing"
        class="files-switch-categories-label cursor-pointer"
        :class="{ 'opacity-50': !isEditable }"
        v-if="!isEditing"
      >
        <template v-if="!isEditing">Agregar categoría al FILE</template>
        <template v-if="isEditing">Actualizar categorías del FILE</template>
      </span>
    </div>
    <div v-else class="files-switch-categories-selected">
      <template v-for="(_category, c) in selectedCategories">
        <a-tag class="tag-category">
          <i class="bi bi-tag"></i>
          {{ showCategory(_category) }}
          <span class="cursor-pointer" @click="removeCategory(c)">
            <i class="bi bi-x-lg"></i>
          </span>
        </a-tag>

        <!-- div class="files-switch-categories-selected-label"></div>
        <div class="escoger-series-icon" @click="removeCategory(c)">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" fill="none">
            <path
              fill="#C4C4C4"
              d="M7.438 4.484V.5h-5.86a.701.701 0 0 0-.703.703v13.594c0 .39.313.703.703.703h9.844c.39 0 .703-.313.703-.703v-9.61H8.141a.705.705 0 0 1-.704-.703Zm1.76 3.12-1.76 2.74 1.76 2.739a.352.352 0 0 1-.296.542H7.88a.353.353 0 0 1-.31-.185c-.575-1.06-1.07-2.012-1.07-2.012-.188.433-.293.586-1.072 2.015a.348.348 0 0 1-.308.185H4.098a.352.352 0 0 1-.296-.542l1.766-2.74-1.766-2.739a.351.351 0 0 1 .296-.542h1.02c.128 0 .248.07.31.185.764 1.43.586.984 1.072 2.007 0 0 .179-.343 1.072-2.007a.353.353 0 0 1 .31-.185h1.02a.35.35 0 0 1 .296.54Zm2.927-3.533v.179h-3.75V.5h.179c.187 0 .366.073.498.205l2.868 2.871a.7.7 0 0 1 .205.495Z"
            />
          </svg>
        </div -->
      </template>
    </div>
  </span>
  <div v-else class="escoger-series">
    <div class="escoger-series-select">
      <div class="escoger-series-header">
        ESCOGER CATEGORÍA
        <div class="escoger-series-icon" @click="close">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" fill="none">
            <path
              fill="#C4C4C4"
              d="M7.438 4.484V.5h-5.86a.701.701 0 0 0-.703.703v13.594c0 .39.313.703.703.703h9.844c.39 0 .703-.313.703-.703v-9.61H8.141a.705.705 0 0 1-.704-.703Zm1.76 3.12-1.76 2.74 1.76 2.739a.352.352 0 0 1-.296.542H7.88a.353.353 0 0 1-.31-.185c-.575-1.06-1.07-2.012-1.07-2.012-.188.433-.293.586-1.072 2.015a.348.348 0 0 1-.308.185H4.098a.352.352 0 0 1-.296-.542l1.766-2.74-1.766-2.739a.351.351 0 0 1 .296-.542h1.02c.128 0 .248.07.31.185.764 1.43.586.984 1.072 2.007 0 0 .179-.343 1.072-2.007a.353.353 0 0 1 .31-.185h1.02a.35.35 0 0 1 .296.54Zm2.927-3.533v.179h-3.75V.5h.179c.187 0 .366.073.498.205l2.868 2.871a.7.7 0 0 1 .205.495Z"
            />
          </svg>
        </div>
      </div>
      <div class="escoger-series-list">
        <template v-for="(_category, c) in filesStore.getFileCategories">
          <div
            @click="selectCategory(_category.value)"
            :class="selectedCategories.indexOf(_category.value) > -1 ? 'active' : ''"
          >
            {{ _category.label }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onBeforeMount } from 'vue';
  import { useFilesStore } from '@store/files';
  const emit = defineEmits(['onChangeCategoriesFile']);

  const filesStore = useFilesStore();

  const isEditing = ref(false);
  const selectedCategories = ref([]);

  const props = defineProps({
    isEditable: true,
    categories: [],
  });

  const close = () => {
    isEditing.value = !isEditing.value;

    save();
  };

  const removeCategory = (_category) => {
    selectedCategories.value.splice(_category, 1);
    save();
  };

  const selectCategory = (serieName) => {
    let index = selectedCategories.value.indexOf(serieName);

    if (index > -1) {
      selectedCategories.value.splice(index, 1);
    } else {
      selectedCategories.value.push(serieName);
    }
    save();
  };

  const save = async () => {
    const params = selectedCategories.value;
    emit('onChangeCategoriesFile', params);
  };

  const showCategory = (_category) => {
    const category = filesStore.getFileCategories.find((category) => category.value === _category);

    return category.label || '';
  };

  onBeforeMount(async () => {
    if (filesStore.getFileCategories.length == 0) {
      await filesStore.fetchFileCategories();
    }
    selectedCategories.value = props.categories.map((category) => category.category_id);
  });
</script>

<style scoped lang="scss">
  .files-switch-categories {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #5c5ab4;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.015em;

    &-label {
      color: #575757;
      padding-left: 5px;
    }

    .ant-switch-handle {
      top: 2px !important;
    }
  }
  .escoger-series {
    position: relative;
    color: #eb5757;
    filter: drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.25));
    z-index: 9999;

    &-select {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 136px;
      background-color: #fff;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      width: 136px;
      height: 38px;
      background-color: #fff;
      padding: 8px 8px 8px 10px;
      align-items: center;
      font-weight: 700;
      font-size: 10px;
      line-height: 17px;
    }

    &-icon {
      width: 28px;
      height: 28px;
      background: #fff2f2;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 5px;
    }

    &-list div {
      padding: 12px 16px 12px 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0.015em;
      color: #212529;
    }

    &-list div:hover,
    &-list div.active {
      background-color: #eb5757;
      color: #fff;
      cursor: pointer;
    }

    &-list {
      overflow: hidden;
      overflow-y: scroll;
      height: 258px;
    }

    &-list::-webkit-scrollbar {
      width: 0.8em;
    }

    &-list::-webkit-scrollbar-track {
    }

    &-list::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      border-radius: 8px;
    }
  }
  .files-switch-categories-selected {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: 10px;
    line-height: 17px;
    color: #5c5ab4;
    gap: 7px;
  }
  .opacity-50 {
    opacity: 0.5;
  }

  .tag-category {
    color: #5c5ab4 !important;
    border-color: #5c5ab4 !important;
  }
</style>
