<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#bd0d12',
        colorInfo: '#bd0d12',
        colorInfoText: '#bd0d12',
        colorPrimaryText: '#bd0d12',
        colorLink: '#bd0d12',
      },
      components: {
        Breadcrumb: {
          fontSize: 12,
        },
      },
    }"
  >
    <RouterView />
  </a-config-provider>
</template>
<style lang="scss">
  @import '@/scss/app.scss';
</style>
