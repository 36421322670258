import request from '../../utils/request';

export function download({
  currentPage,
  perPage,
  filter = '',
  executiveCode = '',
  clientId = '',
  dateRange = '',
  filterNextDays = '',
  revisionStages = '',
}) {
  return request({
    baseURL: window.API,
    method: 'GET',
    url: 'files/download',
    responseType: 'blob',
    params: {
      page: currentPage,
      per_page: perPage,
      filter,
      filter_by: '',
      filter_by_type: '',
      executive_code: executiveCode,
      client_id: clientId,
      date_range: dateRange,
      filter_next_days: filterNextDays,
      revision_stages: revisionStages,
    },
  });
}

export function downloadFilePassengers(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/passenger-export`,
    responseType: 'blob',
  });
}

export function downloadFileFlights(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/export-itinerary-flights`,
    responseType: 'blob',
  });
}

export function downloadFileInvoice(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/statement/download`,
    responseType: 'blob',
  });
}

export function downloadFileSkeleton(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/skeleton-pdf`,
    responseType: 'blob',
  });
}

export function downloadFileItinerary(fileId, imageCover, lang = 'es', type = 'pdf') {
  return request({
    method: 'GET',
    url: `files/${fileId}/itinerary-download-${type}`,
    responseType: 'blob',
    params: {
      lang,
      portada: imageCover ? imageCover : '',
    },
  });
}

export function downloadFileServiceSchedule(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/service-export-schedule`,
    responseType: 'blob',
  });
}

export function downloadFileListHotels(fileId) {
  return request({
    method: 'GET',
    url: `files/hotel-list/${fileId}/export`,
    responseType: 'blob',
  });
}

export function fetchStatementDetailsByFileId(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/statement/details`,
  });
}

export function fetchListHotelsByFileId(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/hotel-list`,
  });
}

export function downloadRoomingListExcel(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/romming-list-excel`,
    responseType: 'blob',
  });
}

export function fetchSkeletonByFileId(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/skeleton`,
  });
}
