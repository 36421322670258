<script lang="ts" setup></script>

<template>
  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2832 9.66982V8.73438H18.0905C18.5365 8.73438 18.8978 8.37311 18.8978 7.92708V7.11979C18.8978 6.67376 18.5365 6.3125 18.0905 6.3125H13.2467C12.8007 6.3125 12.4395 6.67376 12.4395 7.11979V7.92708C12.4395 8.37311 12.8007 8.73438 13.2467 8.73438H14.054V9.66982C8.57908 10.4554 4.36654 15.1513 4.36654 20.8438H26.9707C26.9707 15.1513 22.7582 10.4554 17.2832 9.66982ZM27.778 22.4583H3.55924C3.11322 22.4583 2.75195 22.8196 2.75195 23.2656V24.8802C2.75195 25.3262 3.11322 25.6875 3.55924 25.6875H27.778C28.224 25.6875 28.5853 25.3262 28.5853 24.8802V23.2656C28.5853 22.8196 28.224 22.4583 27.778 22.4583Z"
      fill="#919191"
    />
  </svg>
</template>

<style lang="scss"></style>
