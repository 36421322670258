<script lang="ts" setup></script>

<template>
  <svg fill="none" height="32" viewBox="0 0 31 32" width="31" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1133 9.66982V8.73438H17.9206C18.3666 8.73438 18.7279 8.37311 18.7279 7.92708V7.11979C18.7279 6.67376 18.3666 6.3125 17.9206 6.3125H13.0768C12.6308 6.3125 12.2695 6.67376 12.2695 7.11979V7.92708C12.2695 8.37311 12.6308 8.73438 13.0768 8.73438H13.8841V9.66982C8.40916 10.4554 4.19661 15.1513 4.19661 20.8438H26.8008C26.8008 15.1513 22.5882 10.4554 17.1133 9.66982ZM27.6081 22.4583H3.38932C2.94329 22.4583 2.58203 22.8196 2.58203 23.2656V24.8802C2.58203 25.3262 2.94329 25.6875 3.38932 25.6875H27.6081C28.0541 25.6875 28.4154 25.3262 28.4154 24.8802V23.2656C28.4154 22.8196 28.0541 22.4583 27.6081 22.4583Z"
      fill="#3D3D3D"
    />
  </svg>
</template>

<style lang="scss"></style>
