<script lang="ts" setup></script>

<template>
  <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.30764 2.92794L8.15857 3.00247L8.08404 3.15155L8.0625 3.19462L8.04096 3.15155L7.96643 3.00247L7.81736 2.92794L7.77428 2.9064L7.81736 2.88487L7.96643 2.81033L8.04096 2.66126L8.0625 2.61819L8.08404 2.66126L8.15857 2.81033L8.30764 2.88487L8.35072 2.9064L8.30764 2.92794ZM4.4012 4.17804L4.25211 4.25258L4.17757 4.40168L3.84375 5.06945L3.50993 4.40168L3.43539 4.25258L3.2863 4.17804L2.61803 3.8439L3.2863 3.50977L3.43539 3.43522L3.50993 3.28613L3.84375 2.61835L4.17757 3.28613L4.25211 3.43522L4.4012 3.50977L5.06947 3.8439L4.4012 4.17804ZM13.5988 11.9473L13.7479 11.8727L13.8224 11.7236L14.1562 11.0559L14.4901 11.7236L14.5646 11.8727L14.7137 11.9473L15.382 12.2814L14.7137 12.6155L14.5646 12.6901L14.4901 12.8392L14.1562 13.5069L13.8224 12.8392L13.7479 12.6901L13.5988 12.6155L12.9305 12.2814L13.5988 11.9473ZM15.8716 4.61435L15.8718 4.61449C16.0426 4.78519 16.0424 5.06213 15.8719 5.23235L15.8716 5.23264L5.23277 15.8718C5.14731 15.9573 5.03626 15.9999 4.92334 15.9999C4.8108 15.9999 4.69972 15.9573 4.6142 15.8718L2.12836 13.386L2.12822 13.3858C1.95753 13.2153 1.95733 12.9384 2.12836 12.7674L12.7672 2.12851L12.7675 2.12823C12.8529 2.04271 12.9638 2.00015 13.0767 2.00015C13.1896 2.00015 13.3003 2.04275 13.3852 2.12795L13.3858 2.12851L15.8716 4.61435ZM11.6772 7.81445L12.0308 8.168L12.3843 7.81445L14.9214 5.27734L15.275 4.92379L14.9214 4.57023L13.4299 3.07873L13.0764 2.72517L12.7228 3.07873L10.1857 5.61584L9.83215 5.96939L10.1857 6.32294L11.6772 7.81445Z"
      fill="#EB5757"
      stroke="#EB5757"
    />
  </svg>
</template>

<style lang="scss"></style>
