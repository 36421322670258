<script lang="ts" setup>
  import DetailBoxComponent from '@/quotes/components/DetailBoxComponent.vue';
  import ContentExtensionComponent from '@/quotes/components/ContentExtensionComponent.vue';
  import { toRef } from 'vue';
  import type { QuoteService } from '@/quotes/interfaces';
  import { VueDraggableNext as Draggable } from 'vue-draggable-next';
  import { useQuote } from '@/quotes/composables/useQuote';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  interface Props {
    items: QuoteService[] | GroupedServices[];
    category: number;
  }

  const props = defineProps<Props>();
  const items = toRef(props, 'items');
  const category = toRef(props, 'category');

  interface GroupedServices {
    type: 'group_header' | 'hotel' | 'service';
    service: QuoteService;
    group: QuoteService[];
    selected: false;
  }

  // Order services
  const { orderServices, updateServicesOrder, quoteRowExtentions, alertCategory } = useQuote();
  quoteRowExtentions.id = null;
  quoteRowExtentions.count = 0;
  let totalService = items.value.length - 1;

  const onClose = () => {
    alertCategory.value = null;
  };
</script>

<template>
  <a-alert
    :message="t('quote.label.hotel_changes_manually')"
    type="warning"
    closable
    @close="onClose"
    v-if="alertCategory == category"
  />

  <draggable
    class="dragArea"
    ghost-class="ghost"
    @change="orderServices"
    @end="updateServicesOrder"
    :list="items"
    handle=".handle"
  >
    <template v-for="(service, indexService) in items">
      <ContentExtensionComponent
        :grouped-extension="service"
        :items="service.extensions"
        :services="items"
        v-if="service.type == 'group_extension'"
        :extension="service.service.service.new_extension"
      />

      <DetailBoxComponent
        :totalService="totalService"
        :item="indexService"
        :grouped-service="service"
        v-else
      />
    </template>
  </draggable>
</template>

<style lang="scss">
  .cabecera {
    padding: 20px;
    margin-bottom: 20px;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .quotes-extensiones {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    border: 2px solid #e9e9e9;
    margin-bottom: 24px;
  }
</style>
