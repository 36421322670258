<script lang="ts" setup></script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 2.375H3.33333C2.59695 2.375 2 2.93464 2 3.625V12.375C2 13.0654 2.59695 13.625 3.33333 13.625H12.6667C13.403 13.625 14 13.0654 14 12.375V3.625C14 2.93464 13.403 2.375 12.6667 2.375Z"
      stroke="#3D3D3D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 5.5V10.5"
      stroke="#3D3D3D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33203 8H10.6654"
      stroke="#3D3D3D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style lang="scss"></style>
