<script setup lang="ts"></script>

<template>
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66602 12.6875C2.66602 12.2731 2.84161 11.8757 3.15417 11.5826C3.46673 11.2896 3.89065 11.125 4.33268 11.125H13.3327"
      stroke="#3D3D3D"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="M4.33268 1.75H13.3327V14.25H4.33268C3.89065 14.25 3.46673 14.0854 3.15417 13.7924C2.84161 13.4993 2.66602 13.1019 2.66602 12.6875V3.3125C2.66602 2.8981 2.84161 2.50067 3.15417 2.20765C3.46673 1.91462 3.89065 1.75 4.33268 1.75V1.75Z"
      stroke="#3D3D3D"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
